import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  const element = document.getElementById("scroll");
  useEffect(() => {
    if (element) {
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;