import { Link, useNavigate } from "react-router-dom";
import SignUpLayout from "./SignUpLayout";
import Footer from "../common/Footer";

function AuthHomeScreen() {
    let navigate = useNavigate();
    const login = () => {
        navigate('/login')
    }
    const signup = () => {
        navigate('/signup')
    }
    return (
        <div className="w-100 h-100">
            <main className="login public-main-section w-100 overflow-hidden">
                <div className="row h-100">
                    <div className="col-5 h-100">
                        <SignUpLayout />
                    </div>
                    <div className="col-7 h-100">
                        <section className="h-100 d-flex justify-content-center align-items-center">
                            <div className="w-50 d-flex flex-column justify-content-center align-items-center">
                                <p className="text-gray mb-5 w-75 text-center">
                                    Explore the most exciting courses based on your interests and study major.
                                </p>
                                <Link className="btn btn-green1 btn-lg w-75 fw-bold mb-3" to="/login">
                                  Sign In
                                </Link>
                                <Link className="btn btn-green1 btn-lg w-75 fw-bold" to="/signup">
                                    Sign up
                                </Link>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default AuthHomeScreen;
