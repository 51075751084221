import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function PreviewModel() {
  const [progress, setProgress] = useState(0);
  const {
    avatar,
    signout,
    firstName: authFirstName,
    lastName: authLastName,
  } = useAuth();
  const [showProgress, setShowProgress] = useState(false);
  const modelRef = useRef(null);
  const navigate = useNavigate();
  const [presignedUrl, setPresignedUrl] = useState("");
  const { user, updateUser } = useAuth();

  const onLoad = (e) => {
    // setCameraOrbit(getCameraOrbit(level3))
    setShowProgress(false);
    setProgress(0);
  };
  const onProgress = (e) => {
    if (e.detail.totalProgress < 1) {
      setShowProgress(true);
      setProgress(e.detail.totalProgress);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setPresignedUrl(`/anatomy.glb`);
    } else {
      getModelPresignUrl();
    }
  }, []);

  const getModelPresignUrl = () => {
    setProgress(0);
    setShowProgress(true);
    modelRef.current.showPoster();
    let URL = process.env.REACT_APP_API_URL + "/get-upload-model-presign-url";
    let payload = {
      model_name: `anatomy.glb`,
    };
    axios
      .post(URL, payload, {
        headers: { Authorization: "Bearer " + user.jwtToken },
      })
      .then((res) => {
        if (res && res.data) {
          setPresignedUrl(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  function logout() {
    signout(() => {
      navigate("/");
    });
  }
  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.addEventListener("load", onLoad);
      modelRef.current.addEventListener("progress", onProgress);
    }
    return () => {
      if (modelRef.current) {
        modelRef.current.removeEventListener("load", onLoad);
        modelRef.current.removeEventListener("progress", onProgress);
      }
    };
  }, [modelRef]);

  return (
    <div className="w-100 h-100 bg-green5">
      <header>
        <div className="d-flex align-items-center justify-content-between px-4 py-2 main-header">
          <div className="d-flex align-items-center w-75">
            <div className="pe-4">
              <Link to="/dashboard">
                <img
                  src="/images/Anatomy-unlocked-motif-black.png"
                  alt=""
                  style={{ width: "3rem" }}
                />
              </Link>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h3 className="pb-1 pt-2 m-0 fw-bolder text-green1">
                Functional Anatomy
              </h3>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <a className="btn btn-green3 bg-blue1 border-0 px-2 me-2" href="#">
              <img
                src="/images/menu/torso.png"
                alt="torso"
                style={{ width: "1.5rem" }}
              />
            </a>

            <div className="dropdown">
              <div
                role="button"
                className="avatar fs-6 fw-bold"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  alt=""
                  src={
                    avatar
                      ? `${process.env.REACT_APP_MEDIA_URL}/avatar/${avatar}`
                      : "/images/avatar.png"
                  }
                  className="rounded-circle border border-2 border-white bg-white img-fluid"
                  style={{ width: "3rem", height: "3rem" }}
                />
              </div>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <ul className="p-1 m-0">
                  <li className="d-flex justify-content-center">
                    <img
                      alt=""
                      src={
                        avatar
                          ? `${process.env.REACT_APP_MEDIA_URL}/avatar/${avatar}`
                          : "/images/avatar.png"
                      }
                      className="rounded-circle border border-2 border-white bg-white img-fluid me-2"
                      style={{ width: "2rem", height: "2rem" }}
                    />
                    <span className="fs-5 fw-bolder">
                      {authFirstName} {authLastName}
                    </span>
                  </li>
                </ul>
                <div>
                  <Link
                    to="/my-account"
                    className="dropdown-item fw-bolder fs-6"
                  >
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-6">My Account</div>
                      <div className="col-3"></div>
                    </div>
                  </Link>

                  <Link
                    to="/dashboard"
                    className="dropdown-item fw-bolder fs-6"
                  >
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-6">Dashboard</div>
                      <div className="col-3"></div>
                    </div>
                  </Link>

                  <span
                    role="button"
                    className="dropdown-item fw-bolder fs-6"
                    onClick={logout}
                  >
                    <div className="row">
                      <div className="col-3"></div>
                      <div className="col-6">Logout</div>
                      <div className="col-3"></div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="main-section mx-4 rounded-top-3 bg-white">
        <div className="h-100">
          <div className="row bg-green4 rounded-top-3 m-0 content-header position-relative">
            <div className="position-absolute d-flex w-auto top-0 start-50 translate-middle-x mt-2 align-items-center justify-content-center text-green1">
              <span className="fw-bold">2.0 Abdomen</span>
            </div>
            <div className="col-12 d-flex d-xl-none content-header-top"></div>
            <div className="col-6 d-flex align-items-center justify-content-start">
              <button
                className="btn btn-sm btn-link text-decoration-none fw-bold"
                onClick={() => navigate(-1)}
              >
                <span className="icon-left"></span> Previous
              </button>
            </div>
          </div>
          <div
            className={"height-scroll bg-gray5 overflow-hidden "}
            style={{ position: "relative" }}
          >
            <div className="model-object bg-gray5 h-100 w-100">
              <div className="w-100 h-100 d-flex">
                <model-viewer
                  className="model-viewer"
                  camera-orbit="90deg 90deg 105%"
                  src={presignedUrl}
                  environment-image="/images/hdri/neutral.hdr"
                  alt="Liver"
                  camera-controls
                  ref={modelRef}
                  interaction-prompt="none"
                >
                  <div slot="poster" className="position-relative h-100">
                    <div className="position-absolute top-50 start-50 translate-middle"></div>
                  </div>
                  <div
                    slot="progress-bar"
                    className={
                      "position-relative h-100 " +
                      (showProgress ? "d-block" : "d-none")
                    }
                  >
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <span className="fw-bold text-secondary">
                          {Math.trunc(progress * 100)}%
                        </span>
                      </div>
                    </div>
                  </div>
                </model-viewer>
              </div>
            </div>
          </div>
          <footer className="d-flex justify-content-between px-4 w-100 main-footer bg-green1">
            <div className="d-flex align-items-center">
              <img
                src="/images/logo/anatomy-unlocked-logo-horizontal-white.svg"
                alt=""
                className=""
              />
              <div className="d-flex align-items-center ps-5">
                <span className="text-white fs-8">Built by</span>
                <img
                  src="/images/logo/vection-logo-white.svg"
                  style={{ width: "5rem" }}
                  alt=""
                  className="ps-2"
                />
              </div>
              <Link to="/collaborators" className="ms-5 text-white fs-8">
                Collaborators
              </Link>
              {user && (
                <a
                  href={"/instructions.pdf"}
                  className="ms-3 text-white fs-8"
                  target="_blank"
                >
                  Instructions
                </a>
              )}
            </div>

            <div className="d-flex align-items-center fs-8 text-white">
              <div className="me-3">
                <a
                  href="https://www.iubenda.com/privacy-policy/43897133"
                  className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-white"
                  title="Privacy Policy "
                >
                  Privacy Policy
                </a>
              </div>
              &copy; Copyright 2023
            </div>
          </footer>
        </div>
      </main>
    </div>
  );
}

export default PreviewModel;
