import { Link, Navigate, useParams } from "react-router-dom";
import level1Data from "../data/level1.json";

function Level1Menu() {
  const { level1 } = useParams();
  const modelComingSoon = [
    "male-reproductive-system",
    "female-reproductive-system",
    "skin-and-subcutaneous-layer",
    "musculoskeletal-systems",
    "pelvis-and-perineum",
  ];

  const renderFirstName = (level2) => {
    switch (level2.slug) {
      case "abdomen-and-pelvis":
      case "small-bowel":
      case "digestion":
        return "Digestion";
      case "defecation":
        return "Defecation";
      case "retroperitoneum":
        return "Retroperitoneum";
    }
  };
  const renderSecondName = (level2) => {
    switch (level2.slug) {
      case "abdomen-and-pelvis":
        return <span className="fw-normal">Upper Gastrointestinal Tract</span>;
      case "small-bowel":
        return <span className="fw-normal">Small Bowel</span>;
      case "digestion":
        return <span className="fw-normal">Colon</span>;
      case "defecation":
        return <span className="fw-normal">Rectum, Anus</span>;
      case "retroperitoneum":
        return <span className="fw-normal">Vascular, Urology</span>;
      case "male-reproductive-system":
      case "female-reproductive-system":
      case "abdominal-muscles":
      case "musculoskeletal-systems":
      case "pelvis-and-perineum":
      case "skin-and-subcutaneous-layer":
        return <span className="fw-bold">{level2.name}</span>;
    }
  };
  return (
    <>
      {level1Data[level1] ? "" : <Navigate to="/dashboard" />}
      <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller">
        <div className="bg-white px-5">
          <div className="text-center my-5">
            <h4 className="fw-bold text-black">Torso</h4>
            <span className="text-gray2">Choose a system to learn about.</span>
          </div>
          <div className="row d-flex justify-content-center align-items-center px-5 mx-2 mx-xl-5">
            <div className="card me-3 p-0 mb-3 bg-blue2 border-0 rounded-3 position-relative level1-card">
              <div className="card-body d-flex justify-content-center align-items-center">
                <div className="card-img-top pt-3 justify-content-center d-flex">
                <img
                  className=" img-fluid"
                  src={`/icons/${level1}/abdomen.png`}
                  alt=""
                  />
                </div>
              </div>
              <div className="card-footer border-0 bg-blue1 d-flex justify-content-center align-items-center">
                <Link
                  to={`/${level1}/abdomen`}
                  className="bg-blue1 border-0 rounded-bottom-3 fs-7  text-center text-black fw-bold text-decoration-none stretched-link"
                >
                  <span>2.0 Abdomen</span>
                </Link>
              </div>
            </div>
            {level1Data[level1] &&
              level1Data[level1].children.map((l2, l2Index) => {
                return (
                  <div
                    className="card me-3 p-0 mb-3 bg-blue2 border-0 rounded-3 position-relative level1-card"
                    key={l2Index}
                  >
                    {modelComingSoon.includes(l2.slug) ? (
                      <div className="position-absolute coming-soon-badge fs-7 p-1 fw-bold text-white border-0 rounded-top-3 w-100 d-flex justify-content-center align-items-center">
                        Model Coming Soon
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <img
                        className="card-img-top pt-3"
                        src={`/icons/${level1}/${l2.slug}.svg`}
                        alt={l2.name}
                      />
                    </div>
                    <div className="card-footer border-0 bg-blue1 d-flex justify-content-center align-items-center">
                      <Link
                        to={`/${level1}/${l2.slug}`}
                        className="bg-blue1 border-0 rounded-bottom-3 fs-7  text-center text-black fw-bold text-decoration-none stretched-link"
                      >
                        <span>
                          {l2.version}
                          {"   "}
                          {renderFirstName(l2)}
                          <br /> {renderSecondName(l2)}
                        </span>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </main>
    </>
  );
}

export default Level1Menu;
