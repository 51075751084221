
import { Transforms, Element as SlateElement, Node, Editor, Path,Point } from 'slate'
import { create } from './create'
import { wrapLink } from './helpers'
import isUrl from 'is-url'

export const withAccordion = editor => {
    const { normalizeNode } = editor

    editor.normalizeNode = entry => {
        const [node, path] = entry


        // If the element is a paragraph, ensure its children are valid.
        if (SlateElement.isElement(node) && node.type === 'accordion') {
            for (const [child, childPath] of Node.children(editor, path)) {
                if (SlateElement.isElement(child) && child.type === 'accordion-item') {
                    const [accordionTitle, accordionContent] = child.children;
                    if (accordionTitle && accordionTitle.type !== 'accordion-title') {
                        Transforms.insertNodes(editor, create("accordion-title"), { at: [...childPath, 0] })
                        return;
                    }
                    if (!accordionContent) {
                        Transforms.insertNodes(editor, create("accordion-content"), { at: [...childPath, 1] })
                        return;
                    }
                }

            }
        }
        // return
        // Fall back to the original `normalizNode` to enforce other constraints.
        normalizeNode(entry)
    }

    return editor
}
export const withRef = editor => {
    const { normalizeNode } = editor

    editor.normalizeNode = entry => {
        const [node, path] = entry

        // If the element is a paragraph, ensure its children are valid.
        if (SlateElement.isElement(node) && node.type === 'ref-wrapper') {
            const [ref, refDummy] = node.children;
            for (const [child, childPath] of Node.children(editor, [...path, 0])) {
                if (SlateElement.isElement(child) && !editor.isInline(child)) {
                    if (child.type === "paragraph") {
                        if (Editor.string(editor, childPath) === "") {
                            Transforms.setNodes(editor, { empty: true }, { at: childPath })
                        } else {
                            Transforms.setNodes(editor, { empty: false }, { at: childPath })
                        }
                    }
                    return
                }
            }
            if (ref && ref.type !== 'ref') {
                Transforms.insertNodes(editor, [create("ref")], { at: [...path, 0] })
                return;
            }
            if (!refDummy) {
                Transforms.insertNodes(editor, create("ref-dummy"), { at: [...path, 1] })
                return;
            }

        }
        // Fall back to the original `normalizeNode` to enforce other constraints.
        normalizeNode(entry)
    }

    return editor
}
export const withRef2 = editor => {
    const { normalizeNode } = editor

    editor.normalizeNode = entry => {
        const [node, path] = entry

        // If the element is a paragraph, ensure its children are valid.
        if (SlateElement.isElement(node) && node.type === 'ref') {
            const [p] = node.children;
            if (p && p.type !== 'paragraph') {
                Transforms.setNodes(editor, { ref: true }, { at: [...path, 0] })
                return;
            }

        }
        // Fall back to the original `normalizeNode` to enforce other constraints.
        normalizeNode(entry)
    }

    return editor
}
export const withList = editor => {
    const { normalizeNode } = editor

    editor.normalizeNode = entry => {
        const [node, path] = entry


        // If the element is a paragraph, ensure its children are valid.
        if (SlateElement.isElement(node) && node.type === 'list') {
            for (const [child, childPath] of Node.children(editor, path)) {
                if (SlateElement.isElement(child) && child.type === 'list-item') {
                    const [listItemContent, listItemDummy] = child.children;
                    if (listItemContent && listItemContent.type !== 'list-item-content') {
                        Transforms.insertNodes(editor, create("list-item-content"), { at: [...childPath, 0] })
                        return;
                    }
                    if (!listItemDummy) {
                        Transforms.insertNodes(editor, create("list-item-dummy"), { at: [...childPath, 1] })
                        return;
                    }
                }

            }
        }
        // return
        // Fall back to the original `normalizNode` to enforce other constraints.
        normalizeNode(entry)
    }

    return editor
}
export const withEnd = editor => {
    const { normalizeNode } = editor

    editor.normalizeNode = entry => {
        const [node, path] = entry
        if (Editor.isEditor(node)) {
            const lastNode = Node.get(node, [(node.children.length - 1)])
            if (lastNode.type !== "paragraph" && Editor.string(editor, [(node.children.length - 1)]) !== "") {
                Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { at: [node.children.length] })
                Transforms.select(editor, [(node.children.length - 1)])
                return;
            }
        }


        // Fall back to the original `normalizeNode` to enforce other constraints.
        normalizeNode(entry)
    }

    return editor
}
export const withInlines = editor => {
    const { insertData, insertText, isInline } = editor

    editor.isInline = element =>
        ['link', 'model-link', 'button', 'image-link', 'content-link'].includes(element.type) || isInline(element)

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')

        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor
}

export const withImages = editor => {
    const { isVoid } = editor

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }


    return editor
}
export const withVideos = editor => {
    const { isVoid } = editor

    editor.isVoid = element => {
        return element.type === 'video' ? true : isVoid(element)
    }


    return editor
}
export const withTables = (editor) =>{
    const { deleteBackward, deleteForward, insertBreak} = editor

    editor.deleteBackward = unit =>{
        const { selection }= editor;
        if (selection) {
            const [cell] = Editor.nodes(editor, {
              match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'table-cell',
            })
            const prevNodePath = Editor.before(editor,selection)

            const [tableNode] = Editor.nodes(editor,{
              at:prevNodePath,
              match:n =>!Editor.isEditor(n) && SlateElement.isElement && n.type === 'table-cell'
            })

            if (cell) {
              const [, cellPath] = cell

              const start = Editor.start(editor, cellPath)
              if (Point.equals(selection.anchor, start)) {
                return
              }
            }
            if(!cell && tableNode){
              return
            }
          }
      
          deleteBackward(unit)
    }
    editor.deleteForward = unit => {
        const { selection } = editor
        if (selection) {
          const [cell] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === 'table-cell',
          })
          
          const prevNodePath = Editor.after(editor,selection)
          const [tableNode] = Editor.nodes(editor,{
            at:prevNodePath,
            match:n =>!Editor.isEditor(n) && SlateElement.isElement && n.type === 'table-cell'
          })
          
          
          if (cell) {
            const [, cellPath] = cell
            const end = Editor.end(editor, cellPath)
    
            if (Point.equals(selection.anchor, end)) {
              return
            }
          }
          if(!cell && tableNode){
            return
          }
        }
    
        deleteForward(unit)
      }
    
      editor.insertBreak = () => {
        const { selection } = editor
        if (selection) {
          const [table] = Editor.nodes(editor, {
            match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === 'table',
          })
    
          if (table) {
            return
          }
        }
    
        insertBreak()
      }
    return editor;
}
