import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/main.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";


axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error && 401 === error.response.status) {
    localStorage.removeItem('user');
    window.location.href = "/"
  } else {
    // This is important, so that unhandled cases make axios throw errors
    return Promise.reject(error);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
