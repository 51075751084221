import { Link } from "react-router-dom";
import Profile from "./Profile";

function HeaderMyAccount() {

  return (
    <header>
      <div className="d-flex align-items-center justify-content-between px-4 py-2 main-header">
        <div className="d-flex align-items-center w-75">
          <div className="pe-4">
            <Link to="/dashboard">
              <img
                src="/images/Anatomy-unlocked-motif-black.png"
                alt=""
                style={{ width: "3rem" }}
              />
            </Link>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <h3 className="pb-1 pt-2 m-0 fw-bolder text-green1">
              Functional Anatomy
            </h3>
            {/* <div className="d-flex justify-content-start align-items-center fw-bold">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-gray2">Dashboad</Link></li>
                  <li className="breadcrumb-item active text-gray2">My Account</li>

                </ol>
              </nav>
            </div> */}
          </div>
        </div >
        <div className="d-flex align-items-center">
          <Link to="/dashboard" className="btn btn-green3 me-2 fw-bold">Back to Dashboard</Link>
          <Profile />
        </div>
      </div>
    </header>
  );
}

export default HeaderMyAccount;
