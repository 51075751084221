import React, { useState } from "react";
import SignUpLayout from "./SignUpLayout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../common/Footer";


function Step1({
  email,
  setEmail,
  emailError,
  setEmailError,
  emailErrorMessage,
  setEmailErrorMessage,
  nextBtn1,
  StepProgress
}) {
  return (
    <div
      className="tab-pane"
    >
      <h2 className="fw-bold text-center">Sign Up</h2>
      <p className="text-gray2 my-2 text-center">Please create an account to continue.</p>
      <div className="mt-5 mb-3">
        <input
          type="email"
          className={
            "form-control bg-green5 p-3 " +
            (emailError ? " is-invalid" : "border-0")
          }
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value);
            setEmailErrorMessage("");
          }}
        />
      </div>
      {emailErrorMessage && (
        <div className="text-danger text-center">
          {emailErrorMessage}
        </div>
      )}
      <button
        className="btn btn-green1 fw-bold w-100 btn-lg"
        type="button"
        onClick={nextBtn1}
      >
        Continue
      </button>
      <p className="text-center mt-4 mb-1 text-gray2">
        By proceeding, you agree to the
      </p>
      <div className="text-center mb-5">
        <a href="#" className="text-gray2">Terms of Service</a>
        <span className="text-gray2"> and </span>
        <a href="#" className="text-gray2">Privacy Policy</a>
      </div>
      <StepProgress />
      <p className="text-center mt-5 mb-1 text-gray2">
        Already have an account?
      </p>
      <div className="text-center">
        <Link to="/login" className="btn btn-green3b fw-bold mt-3">Sign In</Link>
      </div>
    </div>
  )
}

function Step2({
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  password,
  setPassword,
  passwordError,
  setPasswordError,
  confirmPassword,
  setConfirmPassword,
  confirmPasswordError,
  setConfirmPasswordError,
  back1,
  nextBtn2,
  StepProgress
}) {
  return (
    <div className="tab-pane">
      <h2 className="fw-bold text-center">About you</h2>
      <p className="text-gray2 my-2 text-center">Please give us some more details.</p>
      <div className="mt-5 mb-3">
        <input
          type="text"
          className={
            "form-control bg-green5 p-3 " +
            (firstNameError ? " is-invalid" : "border-0")
          }
          value={firstName}
          placeholder="First Name"
          onChange={(e) => {
            setFirstNameError(false);
            setFirstName(e.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <input
          type="text"
          className={
            "form-control bg-green5 p-3 " +
            (lastNameError ? " is-invalid" : "border-0")
          }
          value={lastName}
          placeholder="Last Name"
          onChange={(e) => {
            setLastNameError(false);
            setLastName(e.target.value);
          }}
        />
      </div>

      <div className="mb-3">
        <input
          type="password"
          className={
            "form-control bg-green5 p-3 " +
            (passwordError ? " is-invalid" : "border-0")
          }
          value={password}
          placeholder="Password"
          onChange={(e) => {
            setPasswordError(false);
            setPassword(e.target.value);
          }}
        />
      </div>
      <div className="mb-3">
        <input
          type="password"
          className={
            "form-control bg-green5 p-3 " +
            (confirmPasswordError ? " is-invalid" : "border-0")
          }
          value={confirmPassword}
          placeholder="Retype Password"
          onChange={(e) => {
            setConfirmPasswordError(false);
            setConfirmPassword(e.target.value);
          }}
        />
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn btn-green3b fw-bold btn-sm"
          type="button"
          onClick={back1}
        >
          <span className="icon-left"></span> Back
        </button>
        <div className="d-flex align-items-center px-4">
          <StepProgress />
        </div>
        <button
          className="btn btn-green1 btn-sm fw-bold"
          type="button"
          onClick={nextBtn2}
        >
          Continue <span className="icon-right"></span>
        </button>
      </div>
    </div>
  )
}

function Step3({
  role,
  setRole,
  errorMessage,
  setErrorMessage,
  disabled,
  submit,
  back2,
  StepProgress
}) {
  return (
    <div className="tab-pane">
      <h2 className="fw-bold text-center">What role best describes you?</h2>
      <p className="text-gray2 my-2 text-center">Help us personalise your experience.</p>
      <div className="mt-5 mb-3">
        <input
          className="btn-check"
          type="radio"
          name="role"
          id="flexRadioDefault1"
          value={"Student"}
          checked={role === "Student"}
          onChange={(e) => {
            setRole(e.target.value);
          }}
        />
        <label
          className="btn btn-green4 rounded-3 w-100 py-3 text-start"
          for="flexRadioDefault1"
        >
          Student
        </label>
      </div>

      <div className="mb-3">
        <input
          className="btn-check"
          type="radio"
          name="role"
          id="flexRadioDefault2"
          value={"Lecturer"}
          checked={role === "Lecturer"}
          onChange={(e) => {
            setRole(e.target.value);
          }}
        />
        <label
          className="btn btn-green4 rounded-3 w-100 py-3 text-start"
          for="flexRadioDefault2"
        >
          Lecturer
        </label>
      </div>
      {errorMessage && (
        <div className="text-danger text-center">
          {errorMessage}
        </div>
      )}

      <div className="d-flex justify-content-center mt-5">
        <button
          className="btn btn-green3 text-green1 fw-bold btn-sm"
          type="button"
          onClick={back2}
        >
          <span className="icon-left"></span> Back
        </button>
        <div className="d-flex align-items-center px-4">
          <StepProgress />
        </div>
        <button
          className="btn btn-green1 float-end btn-sm fw-bold"
          type="button"
          disabled={disabled}
          onClick={submit}
        >
          Continue <span className="icon-right"></span>
        </button>
      </div>
    </div>
  )
}

function SignUp() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [role, setRole] = useState("Student");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [activeStep, setActiveStep] = useState(1);


  const nextBtn1 = (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailError(true);
      return false;
    } else {
      checkEmailExist();
    }
  }
  const nextBtn2 = () => {
    if (firstName === "") {
      setFirstNameError(true);
    } else if (lastName === "") {
      setLastNameError(true);
    } else if (password === "") {
      setPasswordError(true);
    } else if (confirmPassword === "") {
      setConfirmPasswordError(true);
    } else if (password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      setActiveStep(3);
    }
  }
  function submit() {
    let signUpURL = process.env.REACT_APP_API_URL + "/register";
    let payload = { email, password, firstName: firstName, role };
    axios
      .post(signUpURL, payload)
      .then((res) => {
        setDisabled(false);
        setIsSuccess(true);
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : "Email or password is incorrect";
        setTimeout(() => {
          setDisabled(false);
        }, 500);
        setErrorMessage(message);
      });
  }
  function back1() {
    setActiveStep(1)
  }
  function back2() {
    setActiveStep(2)
  }
  const checkEmailExist = (event) => {
    let checkEmailURL = process.env.REACT_APP_API_URL + "/check-email-exist";
    let payloadEmail = {
      email,
    };
    axios.post(checkEmailURL, payloadEmail).then((response) => {
      if (response.data.message === 1) {
        setEmailError(true);
        setEmailErrorMessage("Email already exist.");
      } else {
        setEmailErrorMessage("");
        setActiveStep(2);
      }
    });
  };

  const StepProgress = () => {
    return (
      <div className={activeStep >= 1 ? "progress-wrap" : "progress-wrap"}>
        <div className="line-progress-bar">
          <ul className="checkout-bar ps-0">
            <li
              className={
                activeStep >= 1
                  ? "progressbar-dots active"
                  : "progressbar-dots"
              }
            >
              <span>1</span>
            </li>
            <div
              className={activeStep >= 2 ? "line active" : "line"}
            ></div>
            <li
              className={
                activeStep >= 2
                  ? "progressbar-dots active"
                  : "progressbar-dots"
              }
            >
              <span>2</span>
            </li>
            <div
              className={
                activeStep >= 3 ? "line stpe2-line active" : "line stpe2-line"
              }
            ></div>
            <li
              className={
                activeStep >= 3 ? "progressbar-dots active" : "progressbar-dots"
              }
            >
              <span>3</span>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className="w-100 h-100">
      <main className="login public-main-section w-100 overflow-hidden">
        <div className="row h-100">
          <div className="col-5 h-100">
            <SignUpLayout />
          </div>
          <div className="col-7 h-100">
            <section className="h-100 d-flex flex-column justify-content-center align-items-center">
              {!isSuccess && (
                <>
                  <form className="form w-50" id="booking-form">
                    <div className="tab-content">
                      {activeStep === 1 ? <Step1
                        email={email}
                        emailError={emailError}
                        setEmail={setEmail}
                        setEmailError={setEmailError}
                        emailErrorMessage={emailErrorMessage}
                        setEmailErrorMessage={setEmailErrorMessage}
                        nextBtn1={nextBtn1}
                        StepProgress={StepProgress}
                      /> : ""}
                      {activeStep === 2 ? <Step2
                        firstName={firstName}
                        setFirstName={setFirstName}
                        firstNameError={firstNameError}
                        setFirstNameError={setFirstNameError}
                        lastName={lastName}
                        setLastName={setLastName}
                        lastNameError={lastNameError}
                        setLastNameError={setLastNameError}
                        password={password}
                        setPassword={setPassword}
                        passwordError={passwordError}
                        setPasswordError={setPasswordError}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        confirmPasswordError={confirmPasswordError}
                        setConfirmPasswordError={setConfirmPasswordError}
                        back1={back1}
                        nextBtn2={nextBtn2}
                        StepProgress={StepProgress}
                      /> : ""}
                      {activeStep === 3 ? <Step3
                        role={role}
                        setRole={setRole}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        disabled={disabled}
                        submit={submit}
                        back2={back2}
                        StepProgress={StepProgress}
                      /> : ""}

                    </div>
                  </form>
                </>
              )}
              {isSuccess && (
                <>
                  <h2 className="w-50 text-center fw-bold">Welcome</h2>
                  <p className="w-50 text-center text-gray2 mb-3">
                    Verification link has been send to your registred email address. Please verify your account.
                  </p>
                  <Link to="/login" className="btn btn-green3b fw-bold">Login</Link>
                </>
              )}
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default SignUp;
