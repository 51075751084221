export const getMenuItem = (menu, slug) => {
    return (
        menu &&
        menu.find((menuItem) => {
            return menuItem.slug === slug;
        })
    );
};

export const getModelName = (level2, level3) => {
    if (level2 === "abdomen-and-pelvis") {
        if (["general-information"].includes(level3)) {
            return "general-information"
        } else if (["stomach", "pancreas", "spleen"].includes(level3)) {
            return "stomach"
        } else if (["liver", "gallbladder"].includes(level3)) {
            return "liver"
        }
    } else if (level2 === "small-bowel") {
        if (["duodenum"].includes(level3)) {
            return "general-information"
        } else if(["general-information","functions","food-digestion","jejunum-ileum","references"].includes(level3)){
            return "jejunum-ileum"
        }
    } else if (level2 === "digestion") {
        return "colon"
    } else if (level2 === "defecation") {
        return "colon"
    } else if (level2 === "retroperitoneum") {
        if (["abdominal-aorta", "inferior-vena-cava"].includes(level3)) {
            return "abdominal-aorta"
        } else if (["adrenal"].includes(level3)) {
            return "kidneys"
        } else if (["kidneys"].includes(level3)) {
            return "kidney-without-ivc"
        } else if (["urethra","ureter","urinary-bladder"].includes(level3)) {
            return "kidney-with-ivc"
        }
    }else if (level2 === "abdominal-muscles") {
        if (["general-information"].includes(level3)) {
            return "abdominal-muscles"
        }
    }
    return "coming-soon"
}
export const getCameraOrbit = (subMenu) => {
    switch (subMenu) {
        case "gallbladder":
            return "2.680749286622902rad 2.833518419877192rad 10.82676376823139m"
        case "spleen":
            return "-2.615442895858645rad 0.9765714845381677rad 13.55368808478995m"
        case "pancreas":
            return "-0.9623181081077046rad 1.4035714784955802rad 10.585591911503423m"
        default:
            return "90deg 90deg 105%"
    }
}

export const getFileName = (fileName) => {
    let fileExtension = fileName.split('.').pop();
    let timeStamp = Math.floor(Date.now() / 1000);
    let newFileName = `${fileName.substr(0, fileName.lastIndexOf(".")).toLowerCase()}-${timeStamp}.${fileExtension}`;
    return newFileName;
}

export const organNames = (level2Data, level1, level2) => {
    let name = level2Data[`${level1}/${level2}`] ? level2Data[`${level1}/${level2}`].version : ""
    switch(level2){
      case "abdomen-and-pelvis":
        return name + " Digestion : Upper Gastrointestinal Tract"
      case "small-bowel" :
        return name + " Digestion : Small Bowel"
      case "digestion" :
        return name + " Digestion : Colon"
      case "defecation" :
        return name + " Defecation : Rectum, Anus"
      case "retroperitoneum" :
        return name + " Retroperitoneum : Vascular, Urology"
      default : 
        return level2Data[`${level1}/${level2}`] ? level2Data[`${level1}/${level2}`].version + " " + level2Data[`${level1}/${level2}`].name : ""
    }
}