import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "@google/model-viewer";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import level2Data from "../data/level2.json";
import level3Data from "../data/level3.json";
import level4Data from "../data/level4.json";
import level5Data from "../data/level5.json";
import { getCameraOrbit, getModelName, organNames } from "../helpers";
import axios from "axios";
import { CameraContext } from "../contexts/CameraContext";
import useAuth from "../hooks/useAuth";
import RichPreview from "./RichPreview";
import { ImageContext } from "../contexts/ImageContext";
import { GalleryContentContext } from "../contexts/GalleryContentContext";

function Contents() {
  const { level1, level2, level3, level4 } = useParams();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState("");
  const modelRef = useRef(null);
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const [contentArr, setContentArr] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOverlay, setIsOverlay] = useState(user.isLoggedIn);
  const [isModelOverlay, setIsModelOverlay] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showModel, setShowModel] = useState(true);
  const { imageDispatch } = useContext(ImageContext);
  const { hash } = useLocation();

  const { galleryContentShow, setGalleryContentShow } = useContext(
    GalleryContentContext
  );

  const initialValue = useMemo(
    () => [
      {
        type: "paragraph",
        children: [{ text: "Loading..." }],
      },
    ],
    []
  );
  const findContent = (data, level5) => {
    return data.find((ele) => {
      return ele.level5 === level5;
    });
  };
  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "/get-slate-content";
    fetchContent(url);
  }, [level1, level2, level3, level4]);

  useEffect(() => {
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };
    const scroll = document.getElementById(removeHashCharacter(hash));
    if (scroll) {
      scroll.scrollIntoView();
    }
  }, [contentArr]);

  const fetchContent = (url) => {
    setLoading(true);
    let payload = {
      level1: level1,
      level2: level2,
      level3: level3,
      level4: level4,
    };
    axios
      .post(url, payload, {
        headers: { Authorization: "Bearer " + user.jwtToken },
      })
      .then((response) => {
        if (response.data) {
          setContentArr([...response.data]);
          imageDispatch({ type: "clear" });
        }
        setLoading(false);
      });
  };
  const onLoad = (e) => {
    setCameraOrbit(getCameraOrbit(level3));
    setShowProgress(false);
    setProgress(0);
  };
  const onProgress = (e) => {
    if (e.detail.totalProgress < 1) {
      setShowProgress(true);
      setProgress(e.detail.totalProgress);
    }
  };
  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.addEventListener("load", onLoad);
      modelRef.current.addEventListener("progress", onProgress);
    }
    return () => {
      if (modelRef.current) {
        modelRef.current.removeEventListener("load", onLoad);
        modelRef.current.removeEventListener("progress", onProgress);
      }
    };
  }, [modelRef, level2, level3]);

  const getModelPresignUrl = (modelName) => {
    setProgress(0);
    setShowProgress(true);
    modelRef.current.showPoster();
    let URL = process.env.REACT_APP_API_URL + "/get-upload-model-presign-url";
    let payload = {
      model_name: `${modelName}.glb`,
    };
    axios
      .post(URL, payload, {
        headers: { Authorization: "Bearer " + user.jwtToken },
      })
      .then((res) => {
        if (res && res.data) {
          setPresignedUrl(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const setCamera = (target, orbit) => {
    if (modelRef.current) {
      modelRef.current.cameraTarget = target;
      modelRef.current.cameraOrbit = orbit;
      // modelRef.current.fieldOfView = "45deg";
    }
  };
  const setCameraTarget = (target) => {
    if (modelRef.current) {
      modelRef.current.cameraTarget = target;
    }
  };
  const setCameraOrbit = (orbit) => {
    if (modelRef.current) {
      modelRef.current.cameraOrbit = orbit;
    }
  };
  const handleOverlayClose = async () => {
    setIsOverlay(true);
    let URL = process.env.REACT_APP_API_URL + "/update-is-loggedIn";
    await axios
      .get(URL, { headers: { Authorization: "Bearer " + user.jwtToken } })
      .then((res) => {
        let userObj = { isLoggedIn: true };
        updateUser(userObj, () => { });
      })
      .catch((err) => console.log(err));
  };
  const renderModelOverlayScreen = () => {
    return (
      <div
        className={"position-absolute d-block h-100 w-50 "}
        style={{ zIndex: 100, backgroundColor: "rgba(0,0,0, 0.6)" }}
      >
        <a
          className="d-flex justify-content-end p-2"
          onClick={() => setIsModelOverlay(!isModelOverlay)}
        >
          <img
            src="/images/close.png"
            alt=""
            style={{ width: "2.5rem", cursor: "pointer" }}
          />
        </a>
        <div className="d-flex flex-column justify-content-center align-items-center h-75">
          <div className="pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="row">
                <div className="col">
                  <img
                    src="/images/click.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "8rem" }}
                  />
                </div>
                <div className="col">
                  <img
                    src="/images/drag.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "8rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="text-center text-white fw-bold fs-5">
              Right click and drag to reposition the model.
              <br />
              Left click and drag to rotate the model.
            </div>
          </div>
          <div className="">
            <div className="d-flex justify-content-center align-items-center">
              <div className="row">
                <div className="col">
                  <img
                    src="/images/scroll.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "7rem" }}
                  />
                </div>
                <div className="col">
                  <img
                    src="/images/pinch.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "8rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="text-center text-white fw-bold fs-5">
              Scroll or pinch and drag to zoom in and out.
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderOverlayScreen = () => {
    return (
      <div
        className={"h-100 position-fixed top-0 start-0 d-block  w-100"}
        style={{ zIndex: 100, backgroundColor: "rgba(0,0,0, 0.6)" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="row">
                <div className="col">
                  <img
                    src="/images/click.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "8rem" }}
                  />
                </div>
                <div className="col">
                  <img
                    src="/images/drag.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "8rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="text-center text-white fw-bold fs-5">
              Right click and drag to reposition the model.
              <br />
              Left click and drag to rotate the model.
            </div>
          </div>
          <div className="">
            <div className="d-flex justify-content-center align-items-center">
              <div className="row">
                <div className="col">
                  <img
                    src="/images/scroll.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "7rem" }}
                  />
                </div>
                <div className="col">
                  <img
                    src="/images/pinch.svg"
                    alt=""
                    className="p-4"
                    style={{ width: "8rem" }}
                  />
                </div>
              </div>
            </div>
            <div className="text-center text-white fw-bold fs-5">
              Scroll or pinch and drag to zoom in and out.
            </div>
            <a
              className="d-flex justify-content-center mt-5"
              onClick={handleOverlayClose}
            >
              <img
                src="/images/close.png"
                alt=""
                style={{ width: "3rem", cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    const modelName = getModelName(level2, level3);
    if (modelName === "coming-soon") {
      setShowModel(false);
    } else {
      setShowModel(true);
      if (process.env.NODE_ENV === "development") {
        setPresignedUrl(`/${modelName}.glb`);
      } else {
        getModelPresignUrl(modelName);
      }
    }
  }, [level2, level3]);
  return (
    <>
      {level3Data[`${level1}/${level2}/${level3}`] ? (
        ""
      ) : (
        <Navigate to="/dashboard" />
      )}
      <CameraContext.Provider
        value={{
          setCamera: setCamera,
          setCameraOrbit: setCameraOrbit,
          setCameraTarget: setCameraTarget,
        }}
      >
        <main className="main-section mx-4 rounded-top-3 bg-white">
          <div className="h-100">
            <div className="row bg-green4 rounded-top-3 m-0 content-header position-relative">
              <div className="position-absolute d-flex w-auto top-0 start-50 translate-middle-x mt-2 align-items-center justify-content-center text-green1">
                <Link
                  to={`/${level1}/${level2}`}
                  className="btn btn-link btn-sm text-decoration-none fw-bold"
                >
                  {organNames(level2Data, level1, level2)}
                  {/* {level2Data[`${level1}/${level2}`] ? level2Data[`${level1}/${level2}`].version + " " + level2Data[`${level1}/${level2}`].name : ""} */}
                </Link>
              </div>
              <div className="col-12 d-flex d-xl-none content-header-top"></div>
              <div className="col-6 d-flex align-items-center justify-content-start">
                <button
                  className="btn btn-sm btn-link text-decoration-none fw-bold"
                  onClick={() => navigate(-1)}
                >
                  <span className="icon-left"></span> Previous
                </button>
                <span className="text-green1 fw-bold pe-1">Copy</span>
                <div
                  onClick={() => {
                    setShowContent(!showContent);
                  }}
                >
                  <img
                    src={showContent ? "/images/show-toggle.png" : "/images/hidetoggleicon.png"}
                    alt=""
                    style={{ width: "4rem" }}
                    className="pe-2 pointer"
                  />
                </div>
                {/* <div className="btn-group">
                  <button
                    className={`btn btn-sm fw-bold ${
                      showContent ? "btn-green3a" : "btn-green1a"
                    }`}
                    onClick={() => {
                      setShowContent(!showContent);
                    }}
                  >
                    Hide content
                  </button>
                  <button
                    className={`btn btn-sm fw-bold ${
                      showContent ? "btn-green1a" : "btn-green3a"
                    }`}
                    onClick={() => {
                      setShowContent(!showContent);
                    }}
                  >
                    Show content
                  </button>
                </div> */}

                <span className="text-green1 fw-bold pe-1">Images</span>
                <div
                  onClick={() => {
                    setGalleryContentShow(!galleryContentShow);
                  }}
                >
                  <img
                    src={
                      galleryContentShow
                        ? "/images/show-toggle.png"
                        : "/images/hidetoggleicon.png"
                    }
                    alt=""
                    style={{ width: "4rem" }}
                    className="pe-2 pointer"
                  />
                </div>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end text-green1 content-header-bottom">
                <div className="dropdown me-3">
                  <div
                    role="button"
                    className="fs-7 fw-bold"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      {level3Data[`${level1}/${level2}/${level3}`]
                        ? level3Data[`${level1}/${level2}/${level3}`].version +
                        " " +
                        level3Data[`${level1}/${level2}/${level3}`].name
                        : ""}
                    </span>{" "}
                    <span className="icon-down"></span>
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {level2Data[`${level1}/${level2}`] &&
                      level2Data[`${level1}/${level2}`].children.map(
                        (l3, l3Index) => {
                          return (
                            <li key={l3Index}>
                              <Link
                                className={
                                  "dropdown-item fs-7 fw-bolder " +
                                  (l3.slug === level3 ? "active" : "")
                                }
                                to={`/${level1}/${level2}/${l3.slug}/${level3Data[`${level1}/${level2}/${l3.slug}`]
                                    .children[0].slug
                                  }`}
                              >
                                {l3.version} {l3.name}
                              </Link>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
                <div className="dropdown">
                  <div
                    role="button"
                    className="fs-7 fw-bold"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      {level4Data[`${level1}/${level2}/${level3}/${level4}`]
                        ? level4Data[`${level1}/${level2}/${level3}/${level4}`]
                          .name
                        : ""}
                    </span>{" "}
                    <span className="icon-down"></span>
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    {level3Data[`${level1}/${level2}/${level3}`] &&
                      level3Data[`${level1}/${level2}/${level3}`].children.map(
                        (l4, l4Index) => {
                          return (
                            <li key={l4Index}>
                              <Link
                                className={
                                  "dropdown-item fs-7 fw-bolder " +
                                  (l4.slug === level4 ? "active" : "")
                                }
                                to={`/${level1}/${level2}/${level3}/${l4.slug}`}
                              >
                                {l4.name}
                              </Link>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={
                "height-scroll bg-gray5 overflow-hidden " +
                (showContent ? "d-flex justify-content-between" : "")
              }
              style={{ position: "relative" }}
            >
              {!isOverlay && showModel ? renderOverlayScreen() : <></>}
              {isModelOverlay && renderModelOverlayScreen()}
              <div
                className={
                  "model-object bg-gray5 h-100 " +
                  (showContent ? "w-50" : "w-100")
                }
              >
                {showModel ? <div
                  className="w-100"
                  onClick={() => setIsModelOverlay(!isModelOverlay)}
                >
                  <img
                    alr=""
                    src="/images/info.svg"
                    className="pt-2 ps-2 pointer-cursor"
                    style={{ width: "2.5rem" }}
                  />
                </div> : ""}

                <div
                  className={`w-100 h-100 bg-green1 position-relative justify-content-center align-items-center ${showModel ? "d-none" : "d-flex"
                    }`}
                >
                  <div className="position-absolute z-0 w-100 h-100 coming-soon-bg1"></div>
                  <div className="position-absolute z-1 w-100 h-100 coming-soon-bg2"></div>
                  <div className="position-absolute z-2 w-100 h-100 d-flex justify-content-center align-items-center">
                    <h1 className="text-white fw-bold">Model Coming Soon</h1>
                  </div>
                </div>
                <div
                  className={`w-100 h-100 ${showModel ? "d-flex" : "d-none"}`}
                >
                  <model-viewer
                    className="model-viewer"
                    camera-orbit={getCameraOrbit(level3)}
                    src={presignedUrl}
                    environment-image="/images/hdri/neutral.hdr"
                    alt="Liver"
                    camera-controls
                    ref={modelRef}
                    interaction-prompt="none"
                  >
                    <div slot="poster" className="position-relative h-100">
                      <div className="position-absolute top-50 start-50 translate-middle"></div>
                    </div>
                    <div
                      slot="progress-bar"
                      className={
                        "position-relative h-100 " +
                        (showProgress ? "d-block" : "d-none")
                      }
                    >
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <div className="position-absolute top-50 start-50 translate-middle">
                          <span className="fw-bold text-secondary">
                            {Math.trunc(progress * 100)}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </model-viewer>
                </div>
              </div>
              <div
                className={
                  "px-4 py-3 bg-white overflow-y-scroll scroller " +
                  (showContent ? "w-50" : "d-none w-0")
                }
                id="scroll"
              >
                <div className="">
                  <h4 className="fw-bold text-black pb-1">
                    {level4Data[`${level1}/${level2}/${level3}/${level4}`]
                      ? `${level4Data[`${level1}/${level2}/${level3}/${level4}`]
                        .version
                      } ${level4Data[`${level1}/${level2}/${level3}/${level4}`]
                        .name
                      }`
                      : ""}
                  </h4>
                  <div className="text-gray2 custom-scroller-content">
                    {loading && (
                      <p className="card-text placeholder-glow">
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                        <span className="placeholder col-12" />
                      </p>
                    )}

                    {!loading ? (
                      level4Data[`${level1}/${level2}/${level3}/${level4}`] &&
                        level4Data[`${level1}/${level2}/${level3}/${level4}`]
                          .children &&
                        level4Data[`${level1}/${level2}/${level3}/${level4}`]
                          .children.length > 0 ? (
                        level4Data[
                          `${level1}/${level2}/${level3}/${level4}`
                        ].children.map((l5, l5Index) => {
                          const content = findContent(contentArr, l5.slug);
                          return (
                            <div
                              key={`${level1}-${level2}-${level3}-${level4}-${l5.slug}-${l5Index}`}
                              id={l5.slug}
                              className="mb-4"
                            >
                              <h5 className="fw-bold text-black pb-1">
                                {/* {`${l5.version} ${l5.name}`} */}
                                {`${l5.name}`}
                              </h5>
                              <RichPreview
                                url={`${level1}/${level2}/${level3}/${level4}/${l5.slug}`}
                                level5={l5.slug}
                                editorValue={content}
                                initialValue={initialValue}
                                loadingContent={loading}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div key={`${level1}-${level2}-${level3}-${level4}-0`}>
                          <RichPreview
                            url={`${level1}/${level2}/${level3}/${level4}`}
                            level5=""
                            editorValue={contentArr[0]}
                            initialValue={initialValue}
                            loadingContent={loading}
                          />
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pt-3 row d-flex justify-content-center">
                    {level4Data[`${level1}/${level2}/${level3}/${level4}`] &&
                      level4Data[`${level1}/${level2}/${level3}/${level4}`]
                        .nav ? (
                      <>
                        <div className="col-12 col-xl-6 mb-2 d-flex justify-content-center justify-content-xl-start">
                          <Link
                            className="btn btn-green1 btn-sm fw-bold"
                            to={`/${level4Data[
                                `${level1}/${level2}/${level3}/${level4}`
                              ].nav[0]
                              }`}
                          >
                            <span>
                              <span className="icon-left fs-6"></span>
                              {level4Data[
                                level4Data[
                                  `${level1}/${level2}/${level3}/${level4}`
                                ].nav[0]
                              ]
                                ? `${level4Data[
                                  level4Data[
                                    `${level1}/${level2}/${level3}/${level4}`
                                  ].nav[0]
                                ].version
                                } ${level4Data[
                                  level4Data[
                                    `${level1}/${level2}/${level3}/${level4}`
                                  ].nav[0]
                                ].name
                                }`
                                : ""}
                            </span>
                          </Link>
                        </div>
                        <div className="col-12 col-xl-6 mb-2 d-flex justify-content-center justify-content-xl-end">
                          <Link
                            className="btn btn-green1 btn-sm fw-bold"
                            to={`/${level4Data[
                                `${level1}/${level2}/${level3}/${level4}`
                              ].nav[1]
                              }`}
                          >
                            <span>
                              {level4Data[
                                level4Data[
                                  `${level1}/${level2}/${level3}/${level4}`
                                ].nav[1]
                              ]
                                ? `${level4Data[
                                  level4Data[
                                    `${level1}/${level2}/${level3}/${level4}`
                                  ].nav[1]
                                ].version
                                } ${level4Data[
                                  level4Data[
                                    `${level1}/${level2}/${level3}/${level4}`
                                  ].nav[1]
                                ].name
                                }`
                                : ""}
                              <span className="icon-right fs-6"></span>
                            </span>
                          </Link>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <Outlet />
            </div>
          </div>
        </main>
      </CameraContext.Provider>
    </>
  );
}

export default Contents;
