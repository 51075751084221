import React from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import level2Data from "../data/level2.json";
import level3Data from "../data/level3.json";
import { generateColsArr } from "../helper";

function Level3Menu() {

  const { level1, level2, level3 } = useParams();
  const colsArr = level3Data[`${level1}/${level2}/${level3}`] ? generateColsArr(level3Data[`${level1}/${level2}/${level3}`].children.length) : [];

  return (
    <>
      {level3Data[`${level1}/${level2}/${level3}`] ? "" : <Navigate to="/dashboard" />}
      <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller">
        <div className="bg-white container">
          <div className="text-center my-5">
            <h4 className="fw-bold text-black">{level3Data[`${level1}/${level2}/${level3}`] ? level3Data[`${level1}/${level2}/${level3}`].version + " " + level3Data[`${level1}/${level2}/${level3}`].name : ""}</h4>
            <span className="text-gray2">Choose an section to get started.</span>
          </div>
          <div className="mt-5"></div>
          <div className="container">
            <div className="row ">
              {colsArr.map((col, colIndex) => {
                return <div className="col-6" key={col}>
                  {col.map((row, rowIndex) => {
                    const l4 = level3Data[`${level1}/${level2}/${level3}`].children[row]
                    return (
                      <div key={row} className="topic d-flex bg-green4 p-3 rounded-3 my-2 position-relative">
                        <Link
                          to={`/${level1}/${level2}/${level3}/${l4.slug}`}
                          className="text-decoration-none text-black stretched-link"
                        >
                          <h6 className="fw-bold m-0">{l4.version} {l4.name}</h6>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              })}
              {level3Data[`${level1}/${level2}/${level3}`] && level3Data[`${level1}/${level2}/${level3}`].children.length === 0 ? <div>No Data</div> : ""}
              <div className="my-4">
                <Link to={`/${level1}/${level2}`} className="btn btn-green3 fw-bolder">
                  <span className="icon-left fs-6"></span> {level2Data[`${level1}/${level2}`] ? level2Data[`${level1}/${level2}`].name : ""}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main >
    </>
  );
}

export default Level3Menu;
