import Profile from "./Profile";
import HeaderCommon from "./HeaderCommon";

function HeaderDashboard() {
    return (
        <header>
            <div className="d-flex align-items-center justify-content-between px-4 py-2 main-header">
                <HeaderCommon />
                <div className="d-flex align-items-center">
                    <Profile />
                </div>
            </div>
        </header>
    );
}

export default HeaderDashboard;
