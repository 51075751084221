import { Link, Navigate, useParams } from "react-router-dom";
import level1Data from "../data/level1.json";
import level2Data from "../data/level2.json";
import { generateColsArr } from "../helper";
import { organNames } from "../helpers";

function Level2Menu() {
  const { level1, level2 } = useParams();
  const colsArr = level2Data[`${level1}/${level2}`] ? generateColsArr(level2Data[`${level1}/${level2}`].children.length) : [];

  return (
    <>
      {level2Data[`${level1}/${level2}`] ? "" : <Navigate to="/dashboard" />}
      <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller">
        <div className="bg-white container">
          <div className="text-center my-5">
            {/* <h4 className="fw-bold text-black">{level2Data[`${level1}/${level2}`] ? level2Data[`${level1}/${level2}`].version + " " + level2Data[`${level1}/${level2}`].name : ""}</h4> */}
            <h4 className="fw-bold text-black">{organNames(level2Data, level1, level2)}</h4>
            <span className="text-gray2">Choose an organ to learn about.</span>
          </div>
          <div className="mt-5"></div>
          <div className="container">
            <div className="row">
              {colsArr.map((col, colIndex) => {
                return <div className="col-6" key={col}>
                  {col.map((row, rowIndex) => {
                    const l3 = level2Data[`${level1}/${level2}`].children[row]
                    return (
                      <div key={row} className="organ d-flex bg-blue2 p-2 rounded-3 my-2 position-relative">
                        <Link
                          to={`/${level1}/${level2}/${l3.slug}`}
                          className="text-decoration-none text-black stretched-link"
                        >
                          <div className="media d-flex">
                            <div className="media-body text-right d-flex align-items-center ">
                              <div className="bg-white rounded-3 p-2 d-flex justify-content-center align-items-center me-2"
                                style={{ width: "3rem", height: "3rem" }}
                              >
                                <img
                                  className="img-fluid"
                                  src={`/icons/${level1}/${level2}/${l3.slug}.svg`}
                                  alt="image"
                                />
                              </div>
                              <h6 className="fw-bold m-0">
                                {l3.version} {l3.name}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              })}
            </div>

            <div className="my-4">
              <Link to={`/${level1}`} className="btn btn-green3 fw-bolder">
                <span className="icon-left fs-6"></span> {level1Data[`${level1}`] ? level1Data[`${level1}`].name : ""}
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Level2Menu;
