import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import SignUpLayout from "./SignUpLayout";
import Footer from "../common/Footer";

function VerifiyEmail() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const url = process.env.REACT_APP_API_URL + "/verify-email";
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  useEffect(() => {
    axios.post(url, { token: token }).then((res) => {
      setMessage('Verification successful, you can now login.');
      setLoading(false)
    }).catch((e) => {
      setMessage('Something went wrong.')
      setLoading(false)
    });
  }, []);
  return (
    <div className="w-100 h-100">
      <main className="login public-main-section w-100 overflow-hidden">
        <div className="row h-100">
          <div className="col-5 h-100">
            <SignUpLayout />
          </div>
          <div className="col-7 h-100">
            <section className="h-100 d-flex flex-column justify-content-center align-items-center">
              {loading ?
                (<p className="text-center w-50 text-gray2">Verifying...</p>)
                : (<>
                  <h2 className="mb-3 fw-bold">Welcome</h2>
                  <p className="text-center w-50 text-gray2">{message}</p>
                  <Link to="/login" className="btn btn-green3b fw-bold">Login</Link>
                </>)}
            </section>
          </div>
        </div>
      </main >
      <Footer />
    </div >
  );
}

export default VerifiyEmail;
