import React, { useCallback, useMemo } from "react";
import { Editable, withReact, Slate } from "slate-react";
import {
  createEditor,
} from "slate";
import { withHistory } from "slate-history";
import {
  withAccordion,
  withImages,
  withInlines,
  withList,
  withRef,
  withVideos,
} from "../editor-common/plugins";
import RichPreviewElement from "./element";
import RichPreviewLeaf from "./leaf";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";


const RichPreview = ({ editorValue, initialValue, url, loadingContent }) => {
  const renderElement = useCallback(
    (props) => <RichPreviewElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <RichPreviewLeaf {...props} />, []);
  const editor = useMemo(
    () =>
      withVideos(
        withImages(
          withInlines(
            withList(
              withRef(withAccordion(withHistory(withReact(createEditor()))))
            )
          )
        )
      ),
    []
  );
  const { user } = useAuth();
  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        {user.role !== "Student" && (
          <Link
            to={`/edit/${url}`}
            className="btn btn-link btn-sm text-secondary d-flex justify-content-end"
          >
            Edit
          </Link>
        )}
      </div>
      {!loadingContent && editorValue && editorValue.content && <Slate editor={editor} value={JSON.parse(editorValue.content)}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="No content found"
          spellCheck={true}
          onKeyDown={(event) => {
            event.preventDefault();
            return;
          }}
        />
      </Slate>
      }
    </React.Fragment>
  );
};

export default RichPreview;

