import { useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import SignUpLayout from "./SignUpLayout";
import Footer from "../common/Footer";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [pwError, setPwError] = useState(false);
  const [cpwError, setCPwError] = useState(false);
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [isSuccess, setIsSuccess] = useState(false);

  function resetPassword(e) {
    e.preventDefault();
    setDisabled(true);
    let resetURL = process.env.REACT_APP_API_URL + "/reset-password/" + token;
    if (password == "") {
      setPwError(true);
      return false;
    } else if (cpassword) {
      setCPwError(true);
    }
    if (cpassword && password) {
      let payload = { password };
      axios
        .post(resetURL, payload)
        .then((res) => {
          setDisabled(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          setCPwError(false);
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : "Somthing went wrong";
          setTimeout(() => {
            setDisabled(false);
          }, 500);
          setErrorMessage(message);
        });
    }
  }
  return (
    <div className="w-100 h-100">
      <main className="login public-main-section w-100 overflow-hidden">
        <div className="row h-100">
          <div className="col-5 h-100">
            <SignUpLayout />
          </div>
          <div className="col-7 h-100">
            <section className="h-100 d-flex flex-column justify-content-center align-items-center">
              {!isSuccess ? (
                <>
                  <h2 className="mb-3 fw-bold">Reset Password</h2>
                  <form className="form w-50 mt-5" onSubmit={resetPassword}>
                    <div className="mb-3">
                      <input
                        type="password"
                        className={
                          "form-control bg-green5 p-3 " +
                          (pwError ? " is-invalid" : "border-0")
                        }
                        id="inputPw"
                        placeholder="Password"
                        onChange={(e) => {
                          setPwError(false);
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className={
                          "form-control bg-green5 p-3 " +
                          (cpwError ? " is-invalid" : "border-0")
                        }
                        id="inputCPw"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          setPwError(false);
                          setCPassword(e.target.value);
                          if (password != e.target.value) {
                            setCPwError(true);
                            setDisabled(true);
                          } else {
                            setCPwError(false);
                            setDisabled(false);
                          }
                        }}
                      />
                    </div>
                    {errorMessage && (
                      <div className="text-danger text-center">
                        {errorMessage}
                      </div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-green1 w-100 fw-bold btn-lg"
                      disabled={disabled}
                    >
                      Done
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <h2 className="w-50 text-center fw-bold">Password Reset Successfully</h2>
                  <p className="w-50 text-center text-gray2 mb-3">
                    Congratulations! Your password has been changed successfully.
                  </p>
                  <Link to="/login" className="btn btn-green3b fw-bold">Login</Link>
                </>
              )}
            </section>
          </div>
        </div>
      </main >
      <Footer />
    </div >
  );
}

export default ResetPassword;
