import { useEffect, useState } from 'react';
import { Editor, Transforms, Element as SlateElement, Range, Text } from 'slate'
import { create } from './create';

export const addList = (editor) => {
    if (isBlockActive(editor, "accordion-title")  || isBlockActive(editor, "table")) {
        return;
    }
    Transforms.insertNodes(editor, [{
        type: "list", wrapperType: "number", children: [
            {
                type: 'list-item',
                children: [create("list-item-content"), create("list-item-dummy")]
            }
        ],
    }])
}
export const addRef = (editor) => {
    if (isBlockActive(editor, "ref") || isBlockActive(editor, "accordion-title") || isBlockActive(editor, "accordion-content") || isBlockActive(editor, "list")  || isBlockActive(editor, "table")) {
        return;
    }
    Transforms.insertNodes(editor, [{
        type: "ref-wrapper", children: [create("ref"), create("ref-dummy")]
    }])
}
export const addAccordion = (editor) => {
    if (isBlockActive(editor, "ref-wrapper") || isBlockActive(editor, "accordion-title") || isBlockActive(editor, "list") || isBlockActive(editor, "table")) {
        return;
    }
    Transforms.insertNodes(editor, [{
        type: 'accordion',
        children: [
            {
                type: 'accordion-item',
                children: [create("accordion-title"), create("accordion-content")]
            }
        ],
    }])
}

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
    if (format === "h1" || format === "h2" || format === "h3" || format === "h4" || format === "h5" || format === "h6") {
        Editor.removeMark(editor, "h1")
        Editor.removeMark(editor, "h2")
        Editor.removeMark(editor, "h3")
        Editor.removeMark(editor, "h4")
        Editor.removeMark(editor, "h5")
        Editor.removeMark(editor, "h6")
    }
    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

export const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}
export const insertModelLink = (editor, id, target, orbit) => {
    if (editor.selection) {
        wrapModelLink(editor, id, target, orbit)
    }
}
export const insertImageLink = (editor, id) => {
    if (editor.selection) {
        wrapImageLink(editor, id)
    }
}
export const insertContentLink = (editor, url) => {
    if (editor.selection) {
        wrapContentLink(editor, url)
    }
}


export const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

export const isContentLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'content-link',
    })
    return !!link
}

export const isModelLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'model-link',
    })
    return !!link
}
export const isImageLinkActive = editor => {
    const [imageLink] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image-link',
    })
    return !!imageLink
}

export const getActiveLinkNode = editor => {
    const { selection } = editor
    const [link] = Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return link
}
export const getActiveModelLinkNode = editor => {
    const { selection } = editor
    if (selection) {

        const [link] = Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'model-link',
        })
        return link
    }
    return [];
}

export const getActiveImageLinkNode = editor => {
    const { selection } = editor
    if (selection) {

        const [imageLink] = Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image-link',
        })
        return imageLink
    }
    return [];
}
export const getActiveContentLinkNode = editor => {
    const { selection } = editor
    const [link] = Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'content-link',
    })
    return link
}


export const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
}
export const unwrapModelLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'model-link',
    })
}
export const unwrapImageLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image-link',
    })
}

export const unwrapContentLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'content-link',
    })
}


export const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}
export const wrapModelLink = (editor, id, target, orbit) => {
    if (isModelLinkActive(editor)) {
        unwrapModelLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'model-link',
        id,
        target,
        orbit
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

export const wrapImageLink = (editor, imageId) => {
    if (isImageLinkActive(editor)) {
        unwrapImageLink(editor)
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const imageLink = {
        type: 'image-link',
        imageId
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, imageLink)
    } else {
        Transforms.wrapNodes(editor, imageLink, { split: true })
    }
}
export const wrapContentLink = (editor, url) => {
    if (isContentLinkActive(editor)) {
        unwrapContentLink(editor)
    }
    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link = {
        type: 'content-link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: 'end' })
    }
}

export const insertImageList = (editor) => {
    if (isBlockActive(editor, "image") || isBlockActive(editor, "video")) {
        return;
    }
    const image = { type: 'image', url: "", children: [{ text: '' }] }
    const imageList = { type: 'image-list', children: [image] }
    Transforms.insertNodes(editor, imageList)
}
export const insertVideoList = (editor) => {
    if (isBlockActive(editor, "image") || isBlockActive(editor, "video")) {
        return;
    }
    const video = { type: 'video', url: "", children: [{ text: '' }] }
    const videoList = { type: 'video-list', children: [video] }
    Transforms.insertNodes(editor, videoList)
}
export const getActiveMarkerNode = editor => {
    const { selection } = editor
   
    const [marker] = Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'marker',
    })
    return marker
}
export const insertMarkerLeaf= (editor, color,format) => {
    if (editor.selection) {
        return wrapMarkerLeaf(editor, color,format)
    }
}
export const wrapMarkerLeaf = (editor, color,format) => {
    if (isMarkerActive(editor)) {
        unwrapMarker(editor)
    }
    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection)
    const marker = {
        color: color,
        range: selection
    }

    const isActive = isMarkActive(editor, format);
    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, marker)
    }
    // const  [...markers] = Editor.nodes(editor, {
    //     at: [],
    //     match: n =>
    //         !Editor.isEditor(n) && Text.isText(n) && n.marker,
    // })
    // return markers;
}
export const unwrapMarker = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'marker',
    })
}
export const isMarkerActive = editor => {
    const [marker] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'marker',
    })
    return !!marker
}

export const usePopup = (popupRef) => {
    const [showPopup,setShowPopup] = useState(false);
    
    useEffect(()=>{
        const handleDocumentClick = (e)=>{
            const clickedComponent = e.target;
            if(!popupRef?.current?.contains(clickedComponent)){
                setShowPopup(false);
            }
        }
        document.addEventListener('click',handleDocumentClick);

        return ()=>{
            document.removeEventListener('click',handleDocumentClick)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [showPopup,setShowPopup];
}
export const insertTable = (editor, rows, columns) => {
    const createRow = (cellText) => {
        const newRow = Array.from(cellText, (value) => createTableCell(value));
        return {
          type: "table-row",
          children: newRow
        };
      };
      
      const createTableCell = (text) => {
        return {
          type: "table-cell",
          children: [{ text }]
        };
      };
    const createTableNode = (cellText) => {
        const tableChildren = Array.from(cellText, (value) => createRow(value));
        let tableNode = { type: "table", children: tableChildren };
        return tableNode;
      };
    const [tableNode] = Editor.nodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table",
          mode: "highest"
    });
      
    if (tableNode) return;
    if (!rows || !columns) {
          return;
    }
    const cellText = Array.from({ length: rows }, () =>
        Array.from({ length: columns }, () => "")
    );
    const newTable = createTableNode(cellText);
    Transforms.insertNodes(editor, [{
        type: 'table-wrapper',
        children: [ newTable ],
    }])
    
    Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [{ text: "" }] },
          { mode: "highest" }
    );
};
