const RichEditorLeaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    if (leaf.h1) {
        children = <span className='h1'>{children}</span>
    }

    if (leaf.h2) {
        children = <span className='h2'>{children}</span>
    }
    if (leaf.h3) {
        children = <span className='h3'>{children}</span>
    }

    if (leaf.h4) {
        children = <span className='h4'>{children}</span>
    }
    if (leaf.h5) {
        children = <span className='h5'>{children}</span>
    }

    if (leaf.h6) {
        children = <span className='h6'>{children}</span>
    }

    return <span {...attributes}>{children}</span>
}
export default RichEditorLeaf;