import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import DeleteUserModal from "../../modal/DeleteUserModal";
import Footer from "../common/Footer";
import HeaderMyAccount from "../common/HeaderMyAccount";

function UserList() {
  const { user } = useAuth();
  const [Users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState({
    show: false, // initial values set to false and null
    id: null,
  });

  const fetchUsers = async () => {
    let URL = process.env.REACT_APP_API_URL + "/get-users";
    axios
      .get(URL, { headers: { Authorization: "Bearer " + user.jwtToken } })
      .then((response) => {
        setUsers(response.data);
      });
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  const handleDelete = (id) => {
    setShowModal({
      show: true,
      id,
    });
  };
  const usersData = (users) => {
    if (users.length > 0) {
      return users.map((userData, i) => {
        return (
          <>
            <tr>
              <td
                className="fw-bold"
                style={
                  user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                }
              >
                {i + 1}
              </td>
              <td
                style={
                  user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                }
              >
                {userData.title} {userData.firstName} {userData.lastName}
              </td>
              <td
                style={
                  user.role != "Admin"
                    ? { width: "25%", lineBreak: "anywhere" }
                    : { width: "20%", lineBreak: "anywhere" }
                }
              >
                {userData.email}
              </td>
              <td
                style={
                  user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                }
              >
                {userData.role}
              </td>
              {user.role == "Admin" && (
                <td>
                  <Link
                    to={"/user/" + userData.id}
                    className="btn btn-green3 text-secondary"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </Link>{" "}
                  <a role="button" onClick={() => handleDelete(userData.id)}>
                    <button type="button" className="btn btn-green3 ms-2">
                      <i className="bi bi-trash3"></i>
                    </button>
                  </a>
                </td>
              )}
            </tr>
          </>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="5" style={{ width: "100%" }}>
            No data found
          </td>
        </tr>
      );
    }
  };
  return (
    <React.Fragment>
      {showModal.show && (
        <DeleteUserModal
          modal={showModal.show}
          setModal={setShowModal}
          id={showModal.id}
          setUsers={setUsers}
        />
      )}

      <div className="w-100 h-100 bg-green5">
        <HeaderMyAccount />
        <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller userList">
          <span className="fs-1 fw-bold lh-sm d-flex justify-content-center">
            Users
          </span>
          {user.role == "Admin" && (
            <div className="add-btn position-absolute end-0">
              <Link
                type="button"
                className="btn btn-green3 me-5"
                to="/user/create-user"
              >
                Add
              </Link>
            </div>
          )}
          <div className="row row-cols-1 row-cols-md-2 g-4 mx-5 my-5 justify-content-md-center custom-scroller ">
            <table className="table table-fixed w-100">
              <thead className="bg-green1 text-white">
                <tr>
                  <th
                    scope="col"
                    style={
                      user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                    }
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    style={
                      user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                    }
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    style={
                      user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                    }
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    style={
                      user.role != "Admin" ? { width: "25%" } : { width: "20%" }
                    }
                  >
                    Role
                  </th>
                  {user.role == "Admin" && <th scope="col">Action</th>}
                </tr>
              </thead>
              <tbody className="custom-scroller">{usersData(Users)}</tbody>
            </table>
          </div>
        </main>
        <Footer />
      </div>
    </React.Fragment>
  );
}
export default UserList;
