import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, Slate } from 'slate-react'
import { Editor, createEditor, Element as SlateElement, Range, Point, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Toolbar } from '../editor-common/components'
import { withAccordion, withEnd, withImages, withInlines, withList, withRef, withVideos,withTables } from '../editor-common/plugins'
import { HOTKEYS } from '../editor-common/config'
import { getActiveImageLinkNode, getActiveModelLinkNode, insertImageLink, insertModelLink, isBlockActive, toggleMark } from '../editor-common/helpers'
import { AddAccordionButton, AddImageListButton, AddLinkButton, AddListButton, AddRefButton, AddVideoListButton, BlockButton, MarkButton, RemoveImageLinkButton, RemoveLinkButton, RemoveModelLinkButton, AddContentLinkButton, RemoveContentLinkButton,AddTable } from '../editor-common/buttons'
import RichEditorElement from './element'
import RichEditorLeaf from './leaf'
import { Link, useParams } from 'react-router-dom'
import { getMenuItem } from '../../helpers';
import AsyncSelect from "react-select/async";
import axios from 'axios'
import useAuth from '../../hooks/useAuth'
import level1Data from "../../data/level1.json";
import level2Data from "../../data/level2.json";
import level3Data from "../../data/level3.json";
import level4Data from "../../data/level4.json";



const RichEditor = () => {
  const { level1, level2, level3, level4, level5 } = useParams();
  const [linkImagesList, setLinkImagesList] = useState([]);
  const renderElement = useCallback(props => <RichEditorElement {...props} />, [])
  const renderLeaf = useCallback(props => <RichEditorLeaf {...props} />, [])
  // const editor = useMemo(() => withList(withEnd(withRef(withAccordion(withHistory(withReact(createEditor())))))), [])
  const editor = useMemo(() => withTables(withVideos(withImages(withInlines(withList(withRef(withAccordion(withHistory(withReact(createEditor()))))))))), [])

  const [content, setContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]);

  const outerRef = useRef(null);
  const imageOuterRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [savingLabel, setSavingLabel] = useState(false);
  const [fetchingLabel, setFetchingLabel] = useState(true);
  const [deleteingLabel, setDeleteingLabel] = useState(false);
  const [annots, setAnnots] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true)
  const [savingContent, setSavingContent] = useState(false)
  const [categories, setCategories] = useState([]);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const { user } = useAuth();

  function toggleOpen() {
    setIsOpen(!isOpen);
  }
  function toggleLinkImageOpen() {
    setIsImageOpen(!isImageOpen);
  }

  const loadImages = () => {
    const match = Array.from(
      Editor.nodes(editor, {
        voids: true,
        at: [],
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "image"
      })
    )
    let imageIds = [];
    match.map(([n, nPath]) => {
      imageIds.push(n.imageId)
    })
    let URL = process.env.REACT_APP_API_URL + `/get-content-images`;
    axios.post(URL, { 'imageIds': imageIds }, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((imageRes) => {
      setLinkImagesList([...linkImagesList, ...imageRes.data])
    })
  }
  const fetchContent = (url) => {
    let payload = {
      "level1": level1,
      "level2": level2,
      "level3": level3,
      "level4": level4,
      "level5": level5 ? level5 : ''
    }
    axios.post(url, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((response) => {
      if (response.data.content) {
        const data = JSON.parse(response.data.content)
        editor.children = data;
        setContent(data)
        loadImages();
      }
      setLoadingContent(false);
    })
  }
  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/get-slate-content-in-edit'
    fetchContent(url)
  }, [level1, level2, level3, level4]);
  useEffect(() => {
    fetchLabel()
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (outerRef && outerRef.current && !outerRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setIsOpen(false);
      }
      if (imageOuterRef && imageOuterRef.current && !imageOuterRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setIsImageOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outerRef, imageOuterRef]);
  const saveContent = () => {
    setSavingContent(true);
    let payload = {
      "level1": level1,
      "level2": level2,
      "level3": level3,
      "level4": level4,
      "level5": level5 ? level5 : '',
      "content": JSON.stringify(content)
    }
    let URL = process.env.REACT_APP_API_URL + "/insert-update-slate-content";
    axios.post(URL, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((response) => {
      setSavingContent(false);
    })
  }

  const fetchLabel = () => {
    let URL = process.env.REACT_APP_API_URL + "/get-labels-by-model";
    let payload = {
      model: level2
    }
    axios.post(URL, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((response) => {
      setAnnots([...response.data])
      setFetchingLabel(false);
    })
  }

  const initialValue = useMemo(
    () =>
      [
        {
          type: 'paragraph',
          children: [{ text: '' }],
        },
      ],
    []
  )
  const linkNode = getActiveModelLinkNode(editor);
  let id = "";
  if (linkNode) {
    const [linkElement, linkPath] = linkNode
    if (linkElement) {
      id = linkElement.id
    }
  }
  const imageLinkNode = getActiveImageLinkNode(editor);
  let imageId = "";
  if (imageLinkNode) {
    const [linkElement, linkPath] = imageLinkNode
    if (linkElement) {
      imageId = linkElement.imageId
    }
  }
  let name = level1 && level1Data[level1].name + "/" + level2Data[`${level1}/${level2}`].name + "/" + level3Data[`${level1}/${level2}/${level3}`].name + "/" + level4Data[`${level1}/${level2}/${level3}/${level4}`].name;
  return (
    <>
      <div className="container w-75 drawers px-4">
        <div className='d-flex justify-content-between align-items-center'>
          <h4 className="subheading fw-bold text-black mt-3 fs-6">{(loadingContent || fetchingLabel) ? "Loading..." : `Edit: ${name}`}</h4>
          <div>
            <button className='btn btn-link btn-sm text-secondary mt-1' onClick={saveContent} >{savingContent ? "Saving..." : "Save"}</button>
            <Link className="drawer-close text-decoration-none ms-auto text-lt-gray-800 mt-2" to={`/${level1}/${level2}/${level3}/${level4}`}>
              <img src="/images/closeblack.png" alt="" style={{ width: "2rem" }} />
            </Link>
          </div>
        </div>
        <div className="overflow-auto custom-scroller w-100 pe-2">
          <div className="dropdown-content d-flex flex-column overflow-hidden">
            <div className='border border-dark border-2 rounded mt-2 editor'>
              <Slate editor={editor} value={initialValue}
                onChange={value => {
                  const isAstChange = editor.operations.some(
                    op => 'set_selection' !== op.type
                  )
                  if (isAstChange) {
                    setContent(value)
                  }
                }}>
                <Toolbar>
                  <MarkButton format="bold" icon="format_bold" />
                  <MarkButton format="italic" icon="format_italic" />
                  <MarkButton format="underline" icon="format_underlined" />
                  <MarkButton format="h1" icon="looks_one" />
                  <MarkButton format="h2" icon="looks_two" />
                  <MarkButton format="h3" icon="looks_3" />
                  <MarkButton format="h4" icon="looks_4" />
                  <MarkButton format="h5" icon="looks_5" />
                  <MarkButton format="h6" icon="looks_6" />
                  <AddAccordionButton format="accordion" icon="apps" />
                  <AddRefButton format="ref" icon="check_box_outline_blank" />
                  <AddListButton format="list" icon="format_list_numbered" />
                  <AddImageListButton />
                  <AddVideoListButton />
                  <AddLinkButton />
                  <RemoveLinkButton />
                  <AddTable format="table" icon="grid_on"/>
                  <div className="btn-group mx-2" ref={outerRef}>
                    <button type="button" className="btn btn-sm btn-secondary dropdown-toggle" onClick={() => {
                      toggleOpen()
                    }} >
                      Add Label
                    </button>
                    <ul className={`dropdown-menu${isOpen ? " show top-100 start-50" : ""}`} >
                      {annots.map((annot, annotIndex) => {
                        return <li key={annotIndex}><button className={"dropdown-item" + (annot._id === id ? " active" : "")} onClick={(event) => {
                          event.preventDefault()
                          if (id) {
                            return
                          }
                          insertModelLink(editor, annot._id, annot.target, annot.orbit)
                        }}>{annot.text}</button></li>
                      })}
                    </ul>
                  </div>
                  <RemoveModelLinkButton />
                  <div className="btn-group mx-2" ref={imageOuterRef}>
                    <button type="button" className="btn btn-sm btn-secondary dropdown-toggle" onClick={() => {
                      toggleLinkImageOpen()
                    }} >
                      Link Image
                    </button>
                    <ul className={`dropdown-menu${isImageOpen ? " show top-100 start-50" : ""}`} >
                      {linkImagesList.map((image, imageIndex) => {
                        return <li key={imageIndex}><button className={"dropdown-item" + (image._id === imageId ? " active" : "")} onClick={(event) => {
                          event.preventDefault()
                          if (imageId) {
                            return
                          }
                          insertImageLink(editor, image._id)
                        }}>{image.file_name}</button></li>
                      })}
                    </ul>
                  </div>
                  <RemoveImageLinkButton />
                  <AddContentLinkButton editor={editor} />
                  <RemoveContentLinkButton />
                </Toolbar>
                <Editable style={{ padding: "10px 17px" }}
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder="Enter some rich text…"
                  spellCheck={true}
                  autoFocus
                  onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                      if (isHotkey(hotkey, event)) {
                        event.preventDefault()
                        const mark = HOTKEYS[hotkey]
                        toggleMark(editor, mark)
                      }
                    }
                    if (isHotkey("shift?+backspace", event)) {
                      const { selection } = editor
                      if (isBlockActive(editor, "accordion-title") || isBlockActive(editor, "accordion-content") || isBlockActive(editor, "ref") || isBlockActive(editor, "list-item-content") || isBlockActive(editor, "list-item-dummy")) {
                        const above = Editor.above(editor, {
                          match: n =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            (n.type === "accordion-title" || n.type === "accordion-content" || n.type === "ref" || n.type === "list-item-content" || n.type === "list-item-dummy"),
                          mode: "lowest"
                        })
                        if (above) {
                          const [node, path] = above;
                          if (Editor.string(editor, path) === "") {
                            event.preventDefault()
                          }
                          if (!(selection && Range.isExpanded(selection))) {
                            const point = Editor.point(editor, selection, { edge: "start" });
                            const startPoint = Editor.start(editor, path);
                            if (Point.equals(point, startPoint)) {
                              event.preventDefault()
                            }
                          }
                        }

                      }
                    }
                    if (isHotkey("shift?+delete", event)) {
                      const { selection } = editor
                      if (isBlockActive(editor, "accordion-title") || isBlockActive(editor, "accordion-content") || isBlockActive(editor, "ref") || isBlockActive(editor, "list-item-content") || isBlockActive(editor, "list-item-dummy")) {
                        const above = Editor.above(editor, {
                          match: n =>
                            !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            (n.type === "accordion-title" || n.type === "accordion-content" || n.type === "ref" || n.type === "list-item-content" || n.type === "list-item-dummy"),
                          mode: "lowest"
                        })
                        if (above) {
                          const [node, path] = above;
                          if (Editor.string(editor, path) === "") {
                            event.preventDefault()
                          }
                          if (!(selection && Range.isExpanded(selection))) {
                            const point = Editor.point(editor, selection, { edge: "end" });
                            const endPoint = Editor.end(editor, path);
                            if (Point.equals(point, endPoint)) {
                              event.preventDefault()
                            }
                          }
                        }
                      }
                    }
                  }
                  }
                />
              </Slate>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RichEditor