import React from 'react'


export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    },
    ref
  ) => (
    <span
      {...props}
      ref={ref}
      className={active ? "text-primary" : ""}
    />
  )
)


export const Icon = React.forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <span
      role="button"
      {...props}
      ref={ref}
      className='material-icons p-1'
    />
  )
)


export const Menu = React.forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <div
      {...props}
      ref={ref}
      className="border-bottom border-2 border-dark d-flex align-items-center"
    />
  )
)

export const Toolbar = React.forwardRef(
  (
    { className, ...props },
    ref
  ) => (
    <Menu
      {...props}
      ref={ref}
    />
  )
)