import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Footer from "../common/Footer";
import HeaderMyAccount from "../common/HeaderMyAccount";

function EditUser() {
  const [title, setTitle] = useState("");
  const [firstName, setFname] = useState("");
  const [lastName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [pwError, setPwError] = useState(false);
  const [confirmPassword, setCPassword] = useState("");
  const [showConfirmPassword, setShowCPassword] = useState(false);
  const [confirmPasswordError, setCPwError] = useState(false);

  const [role, setRole] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const { user } = useAuth();
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [Users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const fetchUsers = async () => {
    let URL = process.env.REACT_APP_API_URL + "/get-user-details/" + id;
    axios
      .get(URL, { headers: { Authorization: "Bearer " + user.jwtToken } })
      .then((response) => {
        setUsers(response.data);
        setFname(response.data.firstName);
        setLname(response.data.lastName);
        setRole(response.data.role);
        setTitle(response.data.title);
        setEmail(response.data.email);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const checkPassword = (event) => {
    let checkPasswordURL =
      process.env.REACT_APP_API_URL + "/check-password-new";
    let payloadPAssword = {
      userId: id,
      password,
    };
    axios
      .post(checkPasswordURL, payloadPAssword, {
        headers: { Authorization: "Bearer " + user.jwtToken },
      })
      .then((response) => {
        if (password != "" && password === Users.passwordHash) {
          setShowCPassword(false);
          setDisabled(false);
        } else if (password != "" && response.data.message === 0) {
          setShowCPassword(true);
        } else {
          setShowCPassword(false);
          setDisabled(false);
        }
      });
  };
  function editUser(e) {
    e.preventDefault();
    setDisabled(true);
    let updateUserURL = process.env.REACT_APP_API_URL + "/update-user";
    let payload = {
      _id: id,
      title,
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      acceptTerms,
      role,
    };
    axios
      .post(updateUserURL, payload, {
        headers: { Authorization: "Bearer " + user.jwtToken },
      })
      .then((res) => {
        if (res.data === 409) {
          setDisabled(true);
          setErrorMessage("Email is already registered");
          setTimeout(() => {
            setDisabled(false);
            setErrorMessage("");
          }, 1000);
        } else {
          setDisabled(false);
          navigate("/users");
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }
  return (
    <div className="w-100 h-100 bg-green5">
      <HeaderMyAccount />
      <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller userList">
        <span className="fs-4 mt-5 fw-bold lh-sm d-flex justify-content-center">
          Edit User
        </span>
        <div className="d-flex justify-content-center">
          <div className="ps-5 pt-5">
            <form className="row g-3 bg-green4 rounded p-3" onSubmit={editUser}>
              <div className="col-6">
                <label
                  htmlFor="validationCustom00"
                  className="form-label fw-bold"
                >
                  Title
                </label>
                <select
                  className="form-select"
                  id="validationCustom00"
                  name="title"
                  required
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  defaultValue={title}
                >
                  <option
                    value="Mr."
                    selected={title == "Mr." && "selected"}
                  >
                    Mr.
                  </option>
                  <option
                    value="Mrs."
                    selected={title == "Mrs." && "selected"}
                  >
                    Mrs.
                  </option>
                  <option
                    value="Miss"
                    selected={title == "Miss" && "selected"}
                  >
                    Miss
                  </option>
                </select>
                <div className="invalid-feedback">
                  Please select a valid title.
                </div>
              </div>
              <div className="col-6">
                <label htmlFor="validationCustom01" className="form-label">
                  First name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom01"
                  name="fname"
                  defaultValue={firstName}
                  required
                  onChange={(e) => {
                    setFname(e.target.value);
                  }}
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="col-6">
                <label htmlFor="validationCustom02" className="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom02"
                  name="lname"
                  defaultValue={lastName}
                  required
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="col-6">
                <label htmlFor="inputEmail" className="form-label">
                  Email
                </label>
                <div className="input-group has-validation">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    name="email"
                    defaultValue={email}
                    required
                    onChange={(e) => {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }}
                  />
                  <div className="invalid-feedback">Please choose a email.</div>
                </div>
              </div>
              <div className="col-6">
                <label htmlFor="inputPassword" className="form-label">
                  Password
                </label>
                <div className="input-group has-validation">
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    name="password"
                    defaultValue={Users.passwordHash}
                    // required
                    onChange={(e) => {
                      setPwError(false);
                      setPassword(e.target.value);
                    }}
                    onBlur={(e) => {
                      checkPassword(e.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    Please choose a password.
                  </div>
                </div>
              </div>
              <div
                className="col-6"
                style={
                  showConfirmPassword
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <label htmlFor="inputCPassword" className="form-label">
                  Confirm Password
                </label>
                <div className="input-group has-validation">
                  <input
                    type="password"
                    className="form-control"
                    id="inputCPassword"
                    name="confirmpassword"
                    required={showConfirmPassword && "required"}
                    onChange={(e) => {
                      setPwError(false);
                      setCPassword(e.target.value);
                      if (password != e.target.value) {
                        setCPwError(true);
                        setDisabled(true);
                      } else {
                        setCPwError(false);
                        setDisabled(false);
                      }
                    }}
                  />
                  <div className="invalid-feedback">
                    Please choose a confimr password.
                  </div>
                </div>
                {confirmPasswordError && (
                  <div className="text-danger text-center">
                    {" "}
                    Confirm password is not matched{" "}
                  </div>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="validationCustom04" className="form-label">
                  Role
                </label>
                <select
                  className="form-select"
                  id="validationCustom04"
                  name="role"
                  required
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  defaultValue={role}
                >
                  <option
                    value="Admin"
                    selected={role == "Admin" && "selected"}
                  >
                    Admin
                  </option>
                  <option
                    value="Student"
                    selected={role == "Student" && "selected"}
                  >
                    Student
                  </option>
                </select>
                <div className="invalid-feedback">
                  Please select a valid role.
                </div>
              </div>
              {errorMessage && (
                <div className="text-danger text-center"> {errorMessage} </div>
              )}
              <div className="col-12">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  disabled={disabled}
                >
                  Edit
                </button>
                &nbsp;
                <Link className="btn btn-primary" to="/users">
                  Close
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default EditUser;
