import { useState } from "react";
import axios from "axios";
import Modal from "./Modal";
import useAuth from "../hooks/useAuth";

function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [disabled, setDisabled] = useState(true);
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const { user } = useAuth();

    function resetPassword(e) {
        e.preventDefault();
        setDisabled(true);
        let resetURL = process.env.REACT_APP_API_URL + "/change-password";
        if (confirmPassword && newPassword) {
            let payload = { oldPassword: oldPassword, newPassword: newPassword, confirmPassword: confirmPassword }
            axios.post(resetURL, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((res) => {
                setDisabled(false);
                setConfirmPasswordError(false);
                setErrorMessage("");
                props.setModal(false);
            }).catch((err) => {
                setConfirmPasswordError(false);
                let message = typeof err.response !== "undefined" ? err.response.data.message : "Somthing went wrong";
                setTimeout(() => {
                    setDisabled(false);
                }, 500);
                setErrorMessage(message);
            });
        }
    }
    return (
        <Modal size="md" modal={props.modal} setModal={props.setModal} modalHeader="Change Password">
            <form onSubmit={resetPassword} className="bg-green4 p-4 rounded">
                <div className="mb-3">
                    <input type="password" value={oldPassword} className={"form-control ps-4 bold " + (oldPasswordError ? " is-invalid" : "border-0")} placeholder="Old Password"
                        onChange={(e) => {
                            setOldPasswordError(false)
                            setOldPassword(e.target.value)
                            if (e.target.value && newPassword && confirmPassword) {
                                setDisabled(false);
                            } else {
                                setDisabled(true);
                            }
                        }}
                    />
                </div>
                <div className="mb-3">
                    <input type="password" value={newPassword} className={"form-control ps-4 bold " + (newPasswordError ? " is-invalid" : "border-0")} placeholder="New Password" onChange={(e) => {
                        setNewPassword(e.target.value)
                        if (e.target.value && oldPassword && confirmPassword) {
                            setDisabled(false);
                            if (newPassword != e.target.value && confirmPassword !== "") {
                                setNewPasswordError(true);
                                setConfirmPasswordError(true);
                                setDisabled(true);
                            } else {
                                setNewPasswordError(false);
                                setConfirmPasswordError(false);
                                setDisabled(false);
                            }
                        } else {
                            setDisabled(true);
                        }

                    }} />
                </div>
                <div className="mb-3">
                    <input type="password" value={confirmPassword} className={"form-control ps-4 bold " + (confirmPasswordError ? " is-invalid" : "border-0")} placeholder="Confirm Password"
                        onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            if (e.target.value && oldPassword && newPassword) {
                                setDisabled(false);
                                if (newPassword != e.target.value) {
                                    setNewPasswordError(true);
                                    setConfirmPasswordError(true);
                                    setDisabled(true);
                                } else {
                                    setNewPasswordError(false);
                                    setConfirmPasswordError(false);
                                    setDisabled(false);
                                }
                            } else {
                                setDisabled(true);
                            }

                        }} />
                </div>
                {errorMessage && <div className="text-danger text-center mb-3"> {errorMessage} </div>}
                <button type="submit" className="btn btn-green1 w-100 fw-bold btn-lg" disabled={disabled}>Save</button>
            </form>
        </Modal>
    );
}

export default ChangePassword;
