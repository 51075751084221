import React, { useState } from "react";
import GalleryModal from "../../modal/GalleryModal";
import Profile from "./Profile";
import HeaderCommon from "./HeaderCommon";
import { Link, useParams } from "react-router-dom";

function Header() {
  const { level1, level2 } = useParams();
  const [showGalleryModel, setShowGalleryModel] = useState(false);
  return (
    <React.Fragment>
      {/* {showGalleryModel && (
        <GalleryModal modal={showGalleryModel} setModal={setShowGalleryModel} />
      )} */}
      <header>
        <div className="d-flex align-items-center justify-content-between px-4 py-2 main-header">
          <HeaderCommon />
          <div className="d-flex align-items-center">
            {level1 && level2 ? (
              <Link
                className="btn btn-green3 bg-blue1 border-0 px-2 me-2"
                to="/torso"
              >
                <img
                  src="/images/menu/torso.png"
                  alt="torso"
                  style={{ width: "1.5rem" }}
                />
              </Link>
            ) : (
              ""
            )}
            {/* <button className="btn btn-green3 px-2 me-2">
              <span onClick={() => {
                setGalleryContentShow(!galleryContentShow)
                }}>
                <img src="/images/gallery.png" alt="torso" />
              </span>
            </button> */}
            {/* <button className="btn btn-green3 px-2 me-2">
              <img src="/images/search.png" alt="torso" />
            </button> */}
            <Profile />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
