import { useState } from "react";
import { Outlet } from "react-router-dom";
import { GalleryContentContext } from "../../contexts/GalleryContentContext";
import Footer from "./Footer";
import Header from "./Header";

function Layout() {
  const [galleryContentShow, setGalleryContentShow] = useState(false);
  return (
    <div className="w-100 h-100 bg-green5">
      <GalleryContentContext.Provider value={{ galleryContentShow, setGalleryContentShow }}>
        <Header />
        <Outlet />
        <Footer />
      </GalleryContentContext.Provider>
    </div>
  );
}
export default Layout;
