import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import Modal from "./Modal";
import { ImageContext } from "../contexts/ImageContext";
import { imageAxios } from "../services/imageAxios";
import useAuth from "../hooks/useAuth";

function ImageModal() {
  const { imageState } = useContext(ImageContext);
  const navigate = useNavigate();
  const { level1, level2, level3, level4, imageId } = useParams();
  const [imageLabel, setImageLabel] = useState("");
  const [imageDescription, setImageDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");
  const { user } = useAuth();

  useEffect(() => {
      imageAxios.imageGet(imageId, user, (response) => {
        const { file_name, label, description } = response;
        const url =
          process.env.REACT_APP_MEDIA_URL + "/" +
          file_name;
        setLoading(false);
        setUrl(url);
        setImageLabel(label);
        setImageDescription(description);
      });
  }, [imageId]);
  const currentIndex = imageState.images
    ? imageState.images.findIndex((imageStateItem, imageStateItemIndex) => {
      if (imageId) {
        return imageId === imageStateItem.imageId;
      }
    })
    : 0;
  return <Modal
    modal={true}
    setModal={() => {
      navigate(`/${level1}/${level2}/${level3}/${level4}`,{ state: "close" });
    }}
    size={"xl"}
    modalBodyClass="p-0 m-3"
    modalHeaderClass="border-0"
  >
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="">
          <img style={{maxHeight:"30rem"}} className="img-fluid" src={loading ? "/images/imageplaceholder.png" : url} alt="" />
      </div>
      {loading ? (
        <div className="mt-4">
          <span className="placeholder w-75 mx-5 " />
          <span className="placeholder w-75 mx-5 " />
        </div>
      ) : (
        <div className="mt-4 image-content mx-5 h-25">
          <div className="fw-bold image-header fs-4">{imageLabel}</div>
          <p className="my-4">{imageDescription}</p>
        </div>
      )}
    </div>
    {currentIndex < imageState.images.length - 1 &&
      imageState.images.length > 1 && (
        <Link
          className="text-secondary text-decoration-none end-0 top-50 translate-middle-y position-absolute bg-transparent"
          to={`/${level1}/${level2}/${level3}/${level4}/image/${imageState.images[currentIndex + 1].imageId
            }`}
          onClick={() => setLoading(true)}
        >
          <span className="icon-right fs-2"></span>
        </Link>
      )}
    {currentIndex > 0 && imageState.images.length > 1 ? (
      <Link
      className="text-secondary text-decoration-none start-0 top-50 translate-middle-y position-absolute bg-transparent"
        to={`/${level1}/${level2}/${level3}/${level4}/image/${imageState.images[currentIndex - 1].imageId
          }`}
        onClick={() => setLoading(true)}
      >
        <span className="icon-left fs-2"></span>
      </Link>
    ) : (
      ""
    )}
  </Modal>
}
export default ImageModal;
