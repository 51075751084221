import Footer from "./common/Footer";
import { Link } from "react-router-dom";
// import Profile from "./common/Profile";
import level1Data from "../data/level1.json";
import HeaderDashboard from "./common/HeaderDashboard";
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";

const courses = ["head-and-neck", "torso", "limbs"]

function CourseCard({ isOpen, info }) {
  return (
    <div className="course-card">
      <div
        className={
          "card p-0 border-0 rounded-3 position-relative h-100 " +
          (isOpen ? "bg-blue2" : "bg-gray4")
        }
      >
        <div className="card-body d-flex justify-content-center align-items-center">
          <div className="image">
            <img
              className="card-img-top img-fuild"
              src={`/icons/${info.slug}.svg`}
              alt={info.name}
            />
          </div>
        </div>
        {isOpen > 0 ? (
          <Link
            className="card-footer bg-blue1 border-0 rounded-bottom-3 text-center text-black text-decoration-none active stretched-link"
            to={`/${info.slug}`}
          >
            <span className="fs-5 fw-bolder">{info.name}</span>
            <br />
            Open
          </Link>
        ) : (
          <div className="card-footer bg-gray3 border-0 rounded-bottom-3 text-center text-gray1">
            <span className="fs-5 fw-bolder">{info.name}</span>
            <br />
            Coming soon
          </div>
        )}
      </div>
    </div>
  )
}

function Dashboard() {
  const { user } = useAuth();
  return (
    <div className="w-100 h-100 bg-green5">
      <HeaderDashboard />
      <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller">
        <div className="bg-white container h-100">
          <div className="d-flex flex-column pt-4 justify-content-center h-100 w-100">
            <div className="">
              <h1 className="fw-bold text-black">{user.firstName}'s Dashboard</h1>
              <h5 className="fw-bolder text-gray2">{user.role}</h5>
            </div>
            <div className="d-flex flex-row pt-4 justify-content-between align-items-center">
              {courses.map((course, courseIndex) => {
                return (
                  <CourseCard key={courseIndex} isOpen={level1Data[course].children.length > 0} info={level1Data[course]} />
                );
              })}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Dashboard;
