import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SignUpLayout from "./SignUpLayout";
import Footer from "../common/Footer";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  function forgotPassword(e) {
    e.preventDefault();
    setDisabled(true);
    if (email == "") {
      setEmailError(true);
      return false;
    } else {
      let url = process.env.REACT_APP_API_URL + "/forgot-password";
      let payload = { email };
      axios
        .post(url, payload)
        .then((res) => {
          setIsSuccess(true);
          setDisabled(false);
        })
        .catch((err) => {
          setIsSuccess(false);
          let message =
            typeof err.response !== "undefined"
              ? err.response.data.message
              : "Email not registered with us.";
          setTimeout(() => {
            setDisabled(false);
          }, 500);
          setEmailError(true)
          setErrorMessage(message);
        });
    }
  }
  return (
    <div className="w-100 h-100">
      <main className="login public-main-section w-100 overflow-hidden">
        <div className="row h-100">
          <div className="col-5 h-100">
            <SignUpLayout />
          </div>
          <div className="col-7 h-100">
            <section className="h-100 d-flex flex-column justify-content-center align-items-center">
              {!isSuccess ? (
                <>
                  <h2 className="mb-3 fw-bold">Forgot Password</h2>
                  <form className="form w-50 mt-5" onSubmit={forgotPassword}>
                    <div className="mb-3">
                      <input
                        type="email"
                        className={
                          "form-control bg-green5 p-3 " +
                          (emailError ? " is-invalid" : "border-0")
                        }
                        id="inputEmail"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmailError(false);
                          setEmail(e.target.value);
                          setDisabled(false);
                        }}
                      />
                    </div>
                    {errorMessage && (
                      <div className="text-danger text-center">
                        {" "}
                        {errorMessage}{" "}
                      </div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-green1 w-100 fw-bold btn-lg"
                      disabled={disabled}
                    >
                      Send
                    </button>
                  </form>
                  <Link to="/login" className="btn btn-green3b fw-bold mt-5">Login</Link>
                </>
              ) : (
                <>
                  <h2 className="w-50 text-center fw-bold">Check your inbox.</h2>
                  <p className="w-50 text-center text-gray2 mb-3">
                    We've sent a reset password link to your registered email address. If you can't see it, check your spam folder in case it has accidentally landed there.
                  </p>
                  <Link to="/login" className="btn btn-green3b fw-bold">Login</Link>
                </>
              )}
            </section>
          </div>
        </div>
      </main >
      <Footer />
    </div >
  );
}

export default ForgotPassword;
