import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import useAuth from "../hooks/useAuth";

function DeleteUserModal(props) {
  const { user } = useAuth();
  function deleteUser() {
    let URL = process.env.REACT_APP_API_URL + "/delete-user";
    let payload = { _id: props.id };
    axios
      .post(URL, payload, { headers: { Authorization: "Bearer " +  user.jwtToken } })
      .then((response) => {
        props.setUsers(response.data);
        props.setModal({
          show: false,
          id: null,
        });
      });
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        className={"modal fade " + (props.modal ? "show " : "")}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={props.modal ? { display: "block" } : { display: "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  props.setModal(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p className="fs-2 text-center">Are you sure?</p>
              <p className="fs-5 text-center">
                Do you really want to delete this user?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  props.setModal(false);
                }}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={deleteUser}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.modal ? <div className="modal-backdrop fade show" /> : ""}
    </React.Fragment>,
    document.getElementById("modal-root")
  );
}
export default DeleteUserModal;
