import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function Footer() {
  const { user } = useAuth();
  return (
    <footer className="d-flex justify-content-between px-4 w-100 main-footer bg-green1">
      <div className="d-flex align-items-center">
        <img
          src="/images/logo/anatomy-unlocked-logo-horizontal-white.svg"
          alt=""
          className=""
        />
        <div className="d-flex align-items-center ps-5">
          <span className="text-white fs-8">Built by</span>
          <img
            src="/images/logo/vection-logo-white.svg"
            style={{ width: "5rem" }}
            alt=""
            className="ps-2"
          />
        </div>
        <Link to="/collaborators" className="ms-5 text-white fs-8">
          Collaborators
        </Link>
        {user && (
          <a
            href={"/instructions.pdf"}
            className="ms-3 text-white fs-8"
            target="_blank"
          >
            Instructions
          </a>
        )}
      </div>

      <div className="d-flex align-items-center fs-8 text-white">
        <div className="me-3">
          <a
            href="https://www.iubenda.com/privacy-policy/43897133"
            className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe text-white"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
        </div>
        &copy; Copyright 2023
      </div>
    </footer>
  );
}

export default Footer;
