export const slugify = (str) => {
    let outStr = "";
    outStr = str.replace(/\W+(?!$)/g, '-').toLowerCase();
    outStr = outStr.replace(/\W$/, '').toLowerCase();
    return outStr
}

export const numerberArr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26"]

export const alphaArr = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]


export const generateColsArr = (length) => {
    if (length <= 0) {
        return [];
    }
    const half = Math.ceil(length / 2);
    const out = [];
    const outFirstHalf = [];
    const outSecondHalf = [];
    for (let i = 0; i < length; i++) {
        if (i < half) {
            outFirstHalf.push(i)
        } else {
            outSecondHalf.push(i)
        }
    }
    out.push(outFirstHalf)
    out.push(outSecondHalf)
    return out
}