import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import SignUpLayout from "./SignUpLayout";
import useAuth from "../../hooks/useAuth";
import Footer from "../common/Footer";

function Login() {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('')
   const [disabled, setDisabled] = useState(false);
   const [emailError, setEmailError] = useState(false);
   const [pwError, setPwError] = useState(false);
   const { user, signin } = useAuth();
   let navigate = useNavigate();
   const [errorMessage, setErrorMessage] = useState("");
   function login(e) {
      e.preventDefault();
      setDisabled(true);
      let loginURL = process.env.REACT_APP_API_URL + "/login";
      if (email == '' && password == '') {
         setEmailError(true)
         setPwError(true)
         return false;
      }
      if (email && password) {
         let payload = { email, password }
         axios.post(loginURL, payload).then((res) => {
            const userObj = {
               jwtToken: res.data.jwtToken,
               firstName: res.data.firstName,
               lastName: res.data.lastName,
               role: res.data.role,
               isLoggedIn: res.data.isLoggedIn,
               email: res.data.email,
               avatar: res.data.avatar ? res.data.avatar : "",
            }
            signin(userObj, () => {
               navigate('/dashboard')
            })
            setDisabled(false);


         }).catch((err) => {
            let message = typeof err.response !== "undefined" ? err.response.data.message : "Email or password is incorrect";
            setTimeout(() => {
               setDisabled(false);
            }, 500);
            setErrorMessage(message)
         });
      } else if (email == '') {
         setEmailError(true);
         setDisabled(false);
      } else if (password == '') {
         setPwError(true);
         setDisabled(false);
      }
   }
   return (
      <div className="w-100 h-100">
         <main className="login public-main-section w-100 overflow-hidden">
            <div className="row h-100">
               <div className="col-5 h-100">
                  <SignUpLayout />
               </div>
               <div className="col-7 h-100">
                  <section className="h-100 d-flex flex-column justify-content-center align-items-center">
                     <h2 className="fw-bold">Sign In</h2>
                     <p className="text-gray2 my-2">Enter your details below</p>
                     <form className="form w-50 mt-5" onSubmit={login}>
                        <div className="mb-3">
                           <input type="email" className={"form-control bg-green5 p-3 " + (emailError ? " is-invalid" : "border-0")} id="inputEmail" placeholder="University email" onChange={(e) => {
                              setEmailError(false)
                              setEmail(e.target.value)
                              if (e.target.value && password) {
                                 setDisabled(false)
                              } else {
                                 setDisabled(true)
                              }
                           }

                           } />
                        </div>
                        <div className="mb-5">
                           <input type="password" className={"form-control bg-green5 p-3 " + (pwError ? " is-invalid" : "border-0")} id="inputPw" placeholder="Password" onChange={(e) => {
                              setPwError(false)
                              setPassword(e.target.value)
                              if (e.target.value && email) {
                                 setDisabled(false)
                              } else {
                                 setDisabled(true)
                              }
                           }} />
                        </div>
                        {errorMessage && <div className="text-danger text-center"> {errorMessage} </div>}
                        <button type="submit" className="btn btn-green1 w-100 fw-bold btn-lg" disabled={disabled}>Continue</button>
                     </form>
                     <Link to="/forgot-password" className="btn btn-link text-gray2 mt-2 mb-4 forgot-password">Forgot Password?</Link>
                     {/* <p className="mt-5 text-gray2">Don't have an account?</p>
                     <Link to="/signup" className="btn btn-green3b fw-bold">Sign up</Link> */}
                  </section>
               </div>
            </div>
         </main>
         <Footer />
      </div>
   );
}

export default Login;
