import { useNavigate } from "react-router-dom";

function Collaborators() {
  const navigate = useNavigate();
  return (
    <main className="bg-secondary h-100 w-100 welcome-bg">
      <div className="container h-100">
        <div className="d-flex h-100 flex-column justify-content-center align-items-center">
          <p className="text-white text-center fs-3 fw-bolder">
            Thank you to all our partners who have provided their <br />{" "}
            knowledge, experience, skills and time to create this <br /> world
            class medical learning platform.
          </p>
          <div className="p-5 w-100">
            <hr className="border-top border-3 border-white" />
            <div className="d-flex justify-content-evenly align-items-center w-100">
              <img src="/images/Anatomy-Unlocked-Brandmark-White.png" alt="" className="" style={{ height: "5rem" }} />
              <img src="/images/logo/Vection-logo.png" alt="" className="" style={{ height: "5rem" }} />
              <img src="/images/logo/Perth-Radiological-clinic-logo.png" alt="" className="" style={{ height: "8rem" }} />
              <img src="/images/logo/UWA-logo.png" alt="" className="" style={{ height: "5rem" }} />
            </div>
            <hr className="border-top border-3 border-white" />
          </div>
          <button className="btn btn-sm btn-link text-decoration-none" onClick={() => navigate(-1)} >
            <img src="/images/close.png" alt="" style={{ width: "3rem" }} />
          </button>
        </div>
      </div>
    </main>
  );
}

export default Collaborators;
