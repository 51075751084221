import React, { useRef, useState } from "react";
import Modal from "./Modal";
import AvatarEditor from 'react-avatar-editor';
import axios from "axios";
import useAuth from "../hooks/useAuth";

function Avatar(props) {
  const fileRef = useRef(null);
  const { avatar, user, updateUser } = useAuth();
  const [fileName, setFileName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [image, setImage] = useState(avatar ? `${process.env.REACT_APP_MEDIA_URL}/avatar/${avatar}` : null);
  const [scale, setScale] = useState(1);
  const editor = useRef(null);

  const getPresignUrl = async (fileName, croppedImg) => {
    let URL = process.env.REACT_APP_API_URL + "/get-avatar-upload-presign-url";
    let today = new Date();
    let timestamp = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
    let payload = {
      'image_name': timestamp + fileName
    }

    await axios.post(URL, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } })
      .then((response) => {
        if (response.status !== 200) {
          setDisabled(false)
        }
        return response;
      })
      .then(result => {
        const s3PresignedUrl = result.data;
        axios({
          method: 'put',
          url: s3PresignedUrl,
          data: dataURLtoFile(croppedImg),
        })
          .then((response) => {
            if (response.status !== 200) {
              setDisabled(false)
            }
            return response;
          })
          .then((res) => {
            let insertImageURL = process.env.REACT_APP_API_URL + "/update-profile";
            let payloadProfile = {
              'avatar': timestamp + fileName,
            }
            axios.post(insertImageURL, payloadProfile, { headers: { Authorization: 'Bearer ' + user.jwtToken } })
              .then((response) => {
                if (response.status !== 200) {
                  setDisabled(false)
                }
                return response;
              })
              .then((imageRes) => {
                setDisabled(false)
                if (fileRef) {
                  fileRef.current.value = null
                  props.fetchUsers();
                  updateUser({ avatar: timestamp + fileName }, () => { })
                  setFileName("");
                  props.setModal(false);
                }
              })
              .catch((err) => {
                setDisabled(false)
              })
          })
          .catch((err) => {
            setDisabled(false)
          })
      })
      .catch((err) => {
        setDisabled(false)
      });
  }

  const handleSave = (e) => {
    setDisabled(true)
    if (editor) {
      const canvasScaled = editor.current.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      getPresignUrl(fileName, croppedImg);
    }
  };

  const handleFileChange = (e) => {
    setDisabled(false);
    let url = URL.createObjectURL(e.target.files[0]);
    setFileName(e.target.files[0].name)
    setImage(url);
  };

  const handleSlider = (e) => {
    setScale(parseInt(e.target.value));
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
  }
  return (
    <Modal modal={props.modal} setModal={props.setModal} modalHeader="Change Avatar">
      <div className="row">
        <div className="col">
          <input ref={fileRef} type="file" name="image"
            onChange={handleFileChange}
            className="form-control fw-bold" />
        </div>
        <div className="col">
          <div className="d-flex flex-column align-items-center">
            <AvatarEditor
              ref={editor}
              image={image}
              width={250}
              height={250}
              border={50}
              borderRadius={150}
              scale={scale}
            />
            {fileName && <input className="form-range mt-3" type="range" id="vol" name="vol"
              min={1}
              max={10}
              step={0.1}
              value={scale}
              onChange={handleSlider}
            />}
            <button className="btn btn-green1 float-end fw-bold mt-3" disabled={disabled || !fileName} onClick={handleSave}>Save</button>
          </div>
        </div>

      </div>
    </Modal>
  );
}

export default Avatar;
