import { useState } from "react";
import Footer from "./common/Footer";
import axios from "axios";
import ChangePassword from "../modal/ChangePassword";
import Avatar from "../modal/Avatar";
import HeaderMyAccount from "./common/HeaderMyAccount";
import useAuth from "../hooks/useAuth";

function MyProfile() {
    const [showChangePasswordModel, setShowChangePasswordModel] = useState(false);
    const [showAvatarModel, setShowAvatarModel] = useState(false);
    const { user, avatar, updateUser, firstName: authFirstName, lastName: authLastName } = useAuth();
    const [firstName, setFirstName] = useState(authFirstName);
    const [lastName, setLastName] = useState(authLastName);
    const [disabled, setDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchUsers = async () => {
        let URL = process.env.REACT_APP_API_URL + "/get-user-details";
        axios
            .get(URL, { headers: { Authorization: "Bearer " + user.jwtToken } })
            .then((response) => {
                const userObj = {
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    role: response.data.role,
                    email: response.data.email,
                    avatar: response.data.avatar ? response.data.avatar : "",
                }
                updateUser(userObj, () => { })
            });
    };

    function updateProfile(e) {
        e.preventDefault();
        setDisabled(true);
        let url = process.env.REACT_APP_API_URL + "/update-profile";
        let payload = { firstName, lastName }
        axios.post(url, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((res) => {
            updateUser({ firstName: firstName, lastName: lastName }, () => {
                setDisabled(false)
            })
        }).catch((err) => {
            let message = typeof err.response !== "undefined" ? err.response.data.message : "Something went wrong";
            setTimeout(() => {
                setDisabled(false);
            }, 500);
            setErrorMessage(message)
        });
    }
    return (
        <div className="w-100 h-100 bg-green5">
            {showChangePasswordModel && (
                <ChangePassword modal={showChangePasswordModel} setModal={setShowChangePasswordModel} />
            )}
            {showAvatarModel && (
                <Avatar modal={showAvatarModel} setModal={setShowAvatarModel} fetchUsers={fetchUsers} />
            )}
            <HeaderMyAccount />
            <main className="main-section mx-4 rounded-top-3 overflow-y-scroll bg-white custom-scroller">
                <div className="bg-white container h-100">
                    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
                        <div className="align-self-start pb-5">
                            <h1 className="fw-bold text-black">My Account</h1>
                            <h6 className="fw-bold text-gray2">{authFirstName} {authLastName}</h6>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className="pe-5">
                                    <img
                                        className="card-img-top my-profile-icon-img rounded-circle"
                                        src={avatar ? `${process.env.REACT_APP_MEDIA_URL}/avatar/${avatar}` : "/images/avatar.png"}
                                    />
                                    <div className="mt-4 d-flex flex-column align-items-center">
                                        <button className="btn btn-green3 btn-sm fw-bold w-75"
                                            onClick={() => {
                                                setShowChangePasswordModel(true);
                                            }}
                                        >
                                            Change Password
                                        </button>
                                        <button className="btn btn-green3 btn-sm mt-3 fw-bold w-75"
                                            onClick={() => {
                                                setShowAvatarModel(true);
                                            }}
                                        >Change Avatar</button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="ps-5">
                                    <form className="row g-3 bg-green4 rounded p-3" onSubmit={updateProfile}>
                                        <div className="col-6">
                                            <label htmlFor="validationCustom01" className="form-label fw-bold">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom01"
                                                name="fname"
                                                value={firstName}
                                                required
                                                disabled={disabled}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                }}
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="validationCustom02" className="form-label fw-bold">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="validationCustom02"
                                                name="fname"
                                                value={lastName}
                                                required
                                                disabled={disabled}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                }}
                                            />
                                            <div className="valid-feedback">Looks good!</div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="inputEmail" className="form-label fw-bold">
                                                Email
                                            </label>
                                            <div className="input-group has-validation">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="inputEmail"
                                                    name="email"
                                                    value={user.email}
                                                    readOnly
                                                    disabled={disabled}
                                                />
                                                <div className="invalid-feedback">
                                                    Please choose a email.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="validationCustom04" className="form-label fw-bold">
                                                Role
                                            </label>
                                            <div className="position-relative">
                                                <input
                                                    type="role"
                                                    className="form-control"
                                                    id="inputRole"
                                                    name="email"
                                                    value={user.role}
                                                    readOnly
                                                    disabled={disabled}
                                                />
                                                <img src="/images/lock.png" alt="" className="position-absolute h-50 top-50 translate-middle end-0 float-end img-fluid pe-1" />

                                            </div>
                                        </div>
                                        {errorMessage && (
                                            <div className="text-danger text-center">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <button
                                                className="btn btn-green1 float-end fw-bold fw-bold"
                                                type="submit"
                                                disabled={disabled}
                                            >
                                                {disabled ? "Saving..." : "Save changes"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default MyProfile;
