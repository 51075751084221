import React from "react";

function Modal(props) {
  return (
    <React.Fragment>
      <div
        className={
          "modal fade " + (props.modal ? "show " : "") + props.modalClass
        }
        tabIndex="-1"
        style={props.modal ? { display: "block" } : { display: "none" }}
      >
        <div
          className={
            "modal-dialog modal-dialog-centered modal-dialog-scrollable modal-" +
            (props.size ? props.size : "lg")
          }
        >
          <div className="modal-content">
            <div className={"modal-header " + props.modalHeaderClass}>
              <div className="modal-title">
                {props.modalHeader && (
                  <h2
                    className="heading mb-1 d-flex align-items-center"
                    id="tableOfContentLiver"
                  >
                    {props.modalHeader}
                  </h2>
                )}
              </div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  props.setModal(false);
                }}
              ></button>
            </div>
            <div className={"modal-body " + props.modalBodyClass}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
      {props.modal ? <div className="modal-backdrop fade show" /> : ""}
    </React.Fragment>
  );
}

export default Modal;
