import { useEffect, useRef, useState } from 'react'
import { useSlate } from 'slate-react'
import { Button, Icon } from './components'
import { addAccordion, addList, addRef, getActiveLinkNode, insertImageList, insertLink, insertVideoList, isBlockActive, isLinkActive, isMarkActive, isModelLinkActive, toggleMark, unwrapLink, unwrapModelLink, getActiveMarkerNode, insertMarkerLeaf, insertContentLink, getActiveContentLinkNode, unwrapContentLink, isContentLinkActive,isImageLinkActive,unwrapImageLink,insertTable, usePopup } from './helpers';
import { Range, Editor, Text,Transforms } from 'slate'
import ModelContentLink from '../../modal/ModelContentLink';

export const AddAccordionButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format
            )}
            onMouseDown={event => {
                event.preventDefault()
                addAccordion(editor);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}
export const AddRefButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format
            )}
            onMouseDown={event => {
                event.preventDefault()
                addRef(editor);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}
export const AddListButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format
            )}
            onMouseDown={event => {
                event.preventDefault()
                addList(editor);
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}

export const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}

export const AddLinkButton = () => {
    const editor = useSlate()
    return (
        <Button
            active={isLinkActive(editor)}
            onMouseDown={event => {
                event.preventDefault()
                const linkNode = getActiveLinkNode(editor);
                let urlString = "";
                if (linkNode) {
                    const [linkElement, linkPath] = linkNode
                    urlString = linkElement.url
                }
                const url = window.prompt('Enter the URL of the link:', urlString)
                if (!url) return
                insertLink(editor, url)
            }}
        >
            <Icon>link</Icon>
        </Button>
    )
}

export const RemoveLinkButton = () => {
    const editor = useSlate()

    return (
        <Button
            active={isLinkActive(editor)}
            onMouseDown={event => {
                if (isLinkActive(editor)) {
                    unwrapLink(editor)
                }
            }}
        >
            <Icon>link_off</Icon>
        </Button>
    )
}
export const RemoveModelLinkButton = () => {
    const editor = useSlate()

    return (
        <button
            className='btn btn-sm btn-secondary'
            active={isModelLinkActive(editor)}
            onMouseDown={event => {
                if (isModelLinkActive(editor)) {
                    unwrapModelLink(editor)
                }
            }}
        >
            Remove Label
        </button>
    )
}
export const RemoveImageLinkButton = () => {
    const editor = useSlate()

    return (
        <button
            className='btn btn-sm btn-secondary'
            active={isImageLinkActive(editor)}
            onMouseDown={event => {
                if (isImageLinkActive(editor)) {
                    unwrapImageLink(editor)
                }
            }}
        >
            Remove Image
        </button>
    )
}
export const AddImageListButton = () => {
    const editor = useSlate()
    return (
        <Button
            onMouseDown={event => {
                event.preventDefault()
                insertImageList(editor)
            }}
        >
            <Icon>image</Icon>
        </Button>
    )
}
export const AddVideoListButton = () => {
    const editor = useSlate()
    return (
        <Button
            onMouseDown={event => {
                event.preventDefault()
                insertVideoList(editor)
            }}
        >
            <Icon>videocam</Icon>
        </Button>
    )
}

export const MarkerButtonForHighlight = ({ format, icon, editor, setShowpicker, showpicker}) => {
      return (
        <Button
            // active={isMarkActive(editor, format)}
            onMouseDown={(event) => {
                event.preventDefault();
                const isCollapsed = editor.selection && Range.isCollapsed(editor.selection)
                if(isCollapsed){
                    return ;
                } else {
                    setShowpicker(!showpicker)
                }
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}
// Add content link button
export const AddContentLinkButton = () => {
    const editor = useSlate();
    const [showModal, setShowModal] = useState(false);
    const [contentLink, setContentLink] = useState(null);
    const [selectedLink, setSelectedLink] = useState(null);
    const [contentSelectionRange, setContentSelectionRange] = useState("");

    const handleSaveContentLink = () => {
        insertContentLink(editor, contentLink.value);
      };
    return (
        <>
            <button
                className='btn btn-sm btn-secondary mx-2'
                active={isContentLinkActive(editor)}
                onMouseDown={(event) => {
                    event.preventDefault();
                    const isCollapsed = editor.selection && Range.isCollapsed(editor.selection)
                    const getContentLink =  getActiveContentLinkNode(editor);
                    setContentSelectionRange(editor.selection)
                    getContentLink && setSelectedLink(getContentLink[0].url); 
                    if (isCollapsed) {
                        return;
                    } else {
                        setShowModal(true)
                    }
                }}
            >
                Add content link
            </button>
            {showModal &&
                <ModelContentLink
                setContentLink={setContentLink}
                contentLink={selectedLink}
                contentSelectionRange={contentSelectionRange}
                handleSaveContentLink={handleSaveContentLink}
                modal={showModal}
                setModal={setShowModal}
              />
            }
        </>
    )
}

export const RemoveContentLinkButton = () => {
    const editor = useSlate()

    return (
        <button
            className='btn btn-sm btn-secondary'
            active={isContentLinkActive(editor)}
            onMouseDown={event => {
                if (isContentLinkActive(editor)) {
                    unwrapContentLink(editor)
                }
            }}
        >
            Remove content link
        </button>
    )
}
export const AddTable = ({ format, icon }) => {
    const tableOptionsRef = useRef();
    const [selection, setSelection] = useState();
    const [showOptions, setShowOptions] = usePopup(tableOptionsRef);
    const [tableData, setTableData] = useState({
        row: 0,
        column: 0
      });
    const editor = useSlate()
    const [tableInput, setTableInput] = useState(
        Array.from({ length: 10 }, () =>
          Array.from({ length: 6 }, (v, i) => ({
            bg: "lightGray",
            column: i
          }))
        )
      );
    useEffect(() => {
    const newTable = Array.from({ length: 10 }, (obj, row) =>
      Array.from({ length: 6 }, (v, col) => ({
        bg:
          row + 1 <= tableData.row && col + 1 <= tableData.column
            ? "orange"
            : "lightgray",
        column: col
      }))
    );
    setTableInput(newTable);
  }, [tableData]);
      const handleButtonClick = () => {
        setSelection(editor.selection);
        setShowOptions((prev) => !prev);
      };
      const handleInsert = () => {
        selection && Transforms.select(editor, selection);
        setTableData({ row: -1, column: -1 });
        insertTable(editor , tableData.row, tableData.column);
        setShowOptions(false);
      };
    return (
        <div ref={tableOptionsRef} className="popup-wrapper">
        <button
          style={{ border: showOptions ? "1px solid lightgray" : "none" }}
          title="table"
          onClick={handleButtonClick}
        >
          <Icon>{icon}</Icon>
        </button>
        {showOptions && (
          <div className="popup">
            {tableData.row >= 1 && (
              <div>
                <i>{`${tableData.row} x ${tableData.column}`}</i>
              </div>
            )}
            <div className="table-input">
              {tableInput.map((grp, row) =>
                grp.map(({ column, bg }) => (
                  <div
                    onClick={() => handleInsert()}
                    onMouseOver={() =>
                      setTableData({ row: row + 1, column: column + 1 })
                    }
                    className="table-unit"
                    style={{ border: `1px solid ${bg}` }}
                  ></div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
     
    )
}