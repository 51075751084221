import axios from 'axios';

export const imageAxios = {
    imageGet(imageId, user, cb){
        let URL = process.env.REACT_APP_API_URL + `/get-image/${imageId}`;
        axios.get(URL, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((imageRes) => {
            if(imageRes.data && (imageId === imageRes.data._id)){
                cb(imageRes.data);
            }
        })
    }
}