import { useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

function AuthProvider({ children }) {
    const localStorageUser = localStorage.getItem("user");
    const initalUser = localStorageUser ? JSON.parse(localStorageUser) : null;
    let [user, setUser] = useState(initalUser);
    let [firstName, setFirstName] = useState(initalUser && initalUser.firstName ? initalUser.firstName : "");
    let [lastName, setLastName] = useState(initalUser && initalUser.lastName ? initalUser.lastName : "");
    let [avatar, setAvatar] = useState(initalUser && initalUser.avatar ? initalUser.avatar : "");

    let signin = (newUser, callback) => {
        localStorage.setItem('user', JSON.stringify(newUser));
        setUser(newUser);
        setFirstName(newUser.firstName ? newUser.firstName : "")
        setLastName(newUser.lastName ? newUser.lastName : "")
        setAvatar(newUser.avatar ? newUser.avatar : "")
        callback();

    };

    let updateUser = (userObj, callback) => {
        const newUser = { ...user, ...userObj };
        localStorage.setItem('user', JSON.stringify(newUser));
        setUser(newUser);
        setFirstName(newUser && newUser.firstName ? newUser.firstName : firstName)
        setLastName(newUser && newUser.lastName ? newUser.lastName : lastName)
        setAvatar(newUser && newUser.avatar ? newUser.avatar : avatar)
        callback();
    }

    let signout = (callback) => {
        localStorage.removeItem('user');
        setUser(null);
        callback();

    };

    let value = { user, firstName, lastName, avatar, signin, signout, updateUser };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;