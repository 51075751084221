import { Link, useParams } from "react-router-dom";
import level1Data from "../../data/level1.json";
import level2Data from "../../data/level2.json";
import level3Data from "../../data/level3.json";
import level4Data from "../../data/level4.json";


function HeaderCommon() {
    const { level1, level2, level3, level4 } = useParams();
    return (
        <div className="d-flex align-items-center w-75">
            <div className="pe-4">
                <Link to="/dashboard">
                    <img
                        src="/images/Anatomy-unlocked-motif-black.png"
                        alt=""
                        style={{ width: "3rem" }}
                    />
                </Link>
            </div>
            <div className="d-flex flex-column justify-content-center">
                <h3 className="pb-1 pt-2 m-0 fw-bolder text-green1">
                    Functional Anatomy
                </h3>
                {/* <div className="d-flex justify-content-start align-items-center fw-bold">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">

                            {
                                level1 ?
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard" className="text-decoration-none text-gray2">
                                            Dashboard
                                        </Link>
                                    </li> :
                                    <li className="breadcrumb-item active text-gray2">
                                        Dashboard
                                    </li>
                            }

                            {
                                (level1 && level1Data[level1]) ?
                                    level2 ?
                                        <li className="breadcrumb-item">
                                            <Link to={`/${level1}`} className="text-decoration-none text-gray2">
                                                {level1Data[level1].name}
                                            </Link>
                                        </li> :
                                        <li className="breadcrumb-item active text-gray2">
                                            {level1Data[level1].name}
                                        </li> :
                                    ""
                            }
                            {
                                (level2 && level2Data[`${level1}/${level2}`]) ?
                                    level3 ?
                                        <li className="breadcrumb-item">
                                            <Link to={`/${level1}/${level2}`} className="text-decoration-none text-gray2">
                                                {level2Data[`${level1}/${level2}`].name}
                                            </Link>
                                        </li> :
                                        <li className="breadcrumb-item active text-gray2">
                                            {level2Data[`${level1}/${level2}`].name}
                                        </li> :
                                    ""
                            }
                            {
                                (level3 && level3Data[`${level1}/${level2}/${level3}`]) ?
                                    level4 ?
                                        <li className="breadcrumb-item">
                                            <Link to={`/${level1}/${level2}/${level3}`} className="text-decoration-none text-gray2">
                                                {level3Data[`${level1}/${level2}/${level3}`].name}
                                            </Link>
                                        </li> :
                                        <li className="breadcrumb-item active text-gray2">
                                            {level3Data[`${level1}/${level2}/${level3}`].name}
                                        </li> :
                                    ""
                            }
                            {
                                (level4 && level4Data[`${level1}/${level2}/${level3}/${level4}`]) ?
                                    <li className="breadcrumb-item active text-gray2">
                                        {level4Data[`${level1}/${level2}/${level3}/${level4}`].name}
                                    </li> :
                                    ""
                            }
                        </ol>
                    </nav>
                </div> */}
            </div>
        </div >
    );
}

export default HeaderCommon;
