import { useSlate, ReactEditor, useSelected, useFocused } from 'slate-react'
import { Editor, Transforms, Element as SlateElement, Path } from 'slate'
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ImageContext } from '../../contexts/ImageContext';
import { CameraContext } from '../../contexts/CameraContext';
import { imageAxios } from '../../services/imageAxios';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import { GalleryContentContext } from '../../contexts/GalleryContentContext';

let i = 0;

const InlineChromiumBugfix = () => (
    <span
        contentEditable={false}
        style={{
            fontSize: "0"
        }}
    >
        ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
)

const LinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected()
    return (
        <a
            className="text-green6 pointer fw-bold"
            {...attributes}
            href="#"
            onClick={(e) => {
                window.open(element.url, "_self");
            }}
            style={{ ...(selected ? { boxShadow: "0 0 0 3px #ddd" } : {}) }}

        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}
const ModelLinkComponent = ({ attributes, children, element }) => {
    const { setCamera } = useContext(CameraContext);
    const selected = useSelected()
    return (
        <button
            {...attributes}
            className="btn btn-link p-0 text-primary fw-bold"
            style={{ ...(selected ? { boxShadow: "0 0 0 3px #ddd" } : {}) }}
            onClick={() => {
                setCamera(element.target, element.orbit)
            }}
        >
            {children}
        </button>
    )
}
const ImageLinkComponent = ({ attributes, children, element }) => {
    const { level1, level2, level3, level4 } = useParams();
    return (
        <Link
            {...attributes}
            href="#"
            className='pointer fw-bold'
            style={{ color: "#d56d10" }}
            state={{ imageId: element.imageId }}
            to={`/${level1}/${level2}/${level3}/${level4}/image/${element.imageId}`}
        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </Link>
    )
}
const ContentLinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected()
    return (
        <a
            className="text-green6 pointer fw-bold"
            {...attributes}
            href="#"
            onClick={(e) => {
                window.open(`/${element.url}`, "_self");
            }}
            style={{ ...(selected ? { boxShadow: "0 0 0 3px #ddd", color: "red" } : {}) }}

        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}

const RichPreviewElement = (props) => {
    const { imageState, imageDispatch } = useContext(ImageContext)
    // const { videoState, videoDispatch } = useContext(VideoContext)
    const { attributes, children, element } = props
    const editor = useSlate()
    const path = ReactEditor.findPath(editor, element);
    const selected = useSelected()
    const focused = useFocused()
    // let location = useLocation();
    const [url, setUrl] = useState('');
    const { level1, level2, level3, level4 } = useParams();
    const { user } = useAuth();
    const { galleryContentShow } = useContext(
        GalleryContentContext
      );
    useEffect(() => {
        if (element.type === "image" && element.imageId) {
            imageAxios.imageGet(
                element.imageId,
                user,
                (response) => {
                    const { file_name } = response;
                    const url = process.env.REACT_APP_MEDIA_URL + "/" + file_name;
                    setUrl(url);
                })
            imageDispatch({ type: "add", imageId: element.imageId })
        }
        if (element.type === "video") {
            // videoDispatch({ type: "add", url: element.url })
        }
    }, [])
    switch (element.type) {
        case 'ref-wrapper':
            return (
                <>
                    {children}
                </>
            )
        case 'ref':
            return (
                <div className='reference'>
                    {/* <h6 className='fw-bold fs-5'>References:</h6> */}
                    {children}
                </div>
            )
        case 'ref-dummy':
            return (
                <></>
            )
        case 'accordion':
            return (
                <div className='accordion accordion-flush'>
                    {children}
                </div>
            )
        case 'accordion-item':
            return (
                <div className='accordion-item mb-1'>
                    {children}
                </div>
            )
        case 'accordion-title':
            i++;
            const nextPath = Path.next(path);
            const [match] = Array.from(
                Editor.nodes(editor, {
                    at: nextPath,
                    match: (n, p) => {
                        return !Editor.isEditor(n) &&
                            SlateElement.isElement(n) &&
                            n.type === "accordion-content" &&
                            n.open &&
                            Path.equals(nextPath, p)
                    },
                    mode: "lowest"
                })
            );
            return (
                <h2 className="accordion-header">
                    <button className={"accordion-button rounded-3 text-gray1 fw-bold " + (i === 1 ? "" : match ? "" : "collapsed")} type="button" onClick={() => {
                        Transforms.setNodes(editor, { open: false }, {
                            at: Path.parent(Path.parent(path)),
                            match: (n, p) => {
                                return !Editor.isEditor(n) &&
                                    SlateElement.isElement(n) &&
                                    n.type === "accordion-content" &&
                                    Path.equals(Path.parent(Path.parent(p)), Path.parent(Path.parent(nextPath)))
                            },
                            mode: 'highest'
                        })
                        Transforms.setNodes(editor, { open: !match }, { at: nextPath, mode: 'highest' })
                    }}>
                        {children}
                    </button>
                </h2>
            )

        case 'accordion-content':
            return (
                <div className={"accordion-collapse collapse " + (i === 1 ? "show" : element.open ? "show" : "")} >
                    <div className="accordion-body">
                        {children}
                    </div>
                </div>
            )
        case 'list':
            switch (element.wrapperType) {
                case "number":
                    return (<ol className='m-0'>{children}</ol>);
                case "bullet":
                    return (<ul>{children}</ul>);
                case "alpha":
                    return (<ol className='alpha m-0' type="A">{children}</ol>);
                case "roman":
                    return (<ul className='roman ps-3'>{children}</ul>);
                default:
                    return (<ol>{children}</ol>);
            }
        case 'list-item':
            return (
                <li>{children}</li>
            )
        case 'list-item-content':
            return (
                <>{children}</>
            )
        case 'list-item-dummy':
            return (
                <></>
            )
        case 'link':
            return <LinkComponent {...props} />
        case 'content-link':
            return <ContentLinkComponent {...props} />
        case 'model-link':
            return <ModelLinkComponent {...props} />
        case 'image-link':
            return <ImageLinkComponent {...props} />
        case 'image-list':
           return galleryContentShow ? <div className='row row-cols-auto'>
            {children} </div>: <></>
        case 'image':
            return (url ? (
                <div className='col mb-3'>
                    <div className="d-flex border border-gray3 overflow-hidden border-5 bg-white rounded position-relative" style={{ height: "10rem" }} id={element.imageId}>
                        <img src={url} className="h-100" />
                        <Link
                            className='stretched-link pointer'
                            state={{ imageId: element.imageId }}
                            to={`/${level1}/${level2}/${level3}/${level4}/image/${element.imageId}`}
                        >
                            <span className="text-green1 position-absolute bottom-0 end-0 me-1">
                                <span className='material-icons'>fullscreen</span>
                            </span>
                        </Link>
                    </div>
                </div>
            ) : (<></>))
        case 'video-list':
            return (
                <figure>
                    <div className='image-group'>
                        {children}
                    </div>
                </figure>
            )
        case 'video':
            return (element.url ? (
                <figure>
                    <div className="img-thumb">
                        <iframe width="100%" height="auto" src={element.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <Link
                            to="#"
                        // to={dropdown ? `/${level1}/${level2}/${level3}/${level4}/video?url=${element.url}` : `/${level1}/${level2}/${level3}/${level4}/video?url=${element.url}`}
                        >
                            <span className="img-thumb-btn text-decoration-none"><i className="icon-general icon-zoom"></i></span>
                        </Link>
                    </div>
                    <figcaption>{element.title}</figcaption>
                </figure>
            ) : (<></>))
        case 'table':
            return (
                <table className='preview-table'>
                    <tbody {...attributes}>{children}</tbody>
                </table>
            )
         case 'table-row':
            return <tr {...attributes}>{children}</tr>
         case 'table-cell':
            return <td {...attributes}>{children}</td>
        default:
            const { className, ...rest } = attributes
            if (element.empty) {
                return (<></>)
            }
            return (
                <p {...rest} className={className ? `${className} m-0` : `m-0`}>
                    {children}
                </p>
            )
    }
}
export default RichPreviewElement;