import { useSlate, ReactEditor, useSelected, useFocused } from 'slate-react'
import { Editor, Transforms, Element as SlateElement, Path } from 'slate'
import { create } from '../editor-common/create';
import isUrl from 'is-url';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { getFileName } from '../../helpers';
import { imageAxios } from '../../services/imageAxios';
import useAuth from '../../hooks/useAuth';


const InlineChromiumBugfix = () => (
    <span
        contentEditable={false}
        style={{
            fontSize: "0"
        }}
    >
        ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
)

const LinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected()
    return (
        <a
            {...attributes}
            href={element.url}
            style={{ ...(selected ? { boxShadow: "0 0 0 3px #ddd" } : {}) }}

        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}
const ContentLinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected()
    return (
        <a
            className='text-green6 fw-bold'
            {...attributes}
            href={element.url}
            style={{ ...(selected ? { boxShadow: "0 0 0 3px #ddd" } : {}) }}

        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}
const ModelLinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected()
    return (
        <a
            {...attributes}
            href={element.id}
            className="text-danger p-0"
        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}
const ImageLinkComponent = ({ attributes, children, element }) => {
    return (
        <a
            {...attributes}
            href={element.imageId}
            className='p-0'
            style={{ color: "#d56d10" }}
        >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )
}
const RichEditorElement = (props) => {



    const { attributes, children, element } = props
    const editor = useSlate()
    const path = ReactEditor.findPath(editor, element);
    const selected = useSelected()
    const focused = useFocused()
    const [file, setFile] = useState(null);
    const [upload, setUpload] = useState(false);
    const [imageLabel, setImageLabel] = useState("");
    const [imageDescription, setImageDescription] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false)
    const fileRef = useRef();
    const [url, setUrl] = useState('');
    const { user } = useAuth();

    useEffect(() => {
        if (element.type === 'image' && element.imageId) {
            imageAxios.imageGet(
                element.imageId,
                user,
                (response) => {
                    const { label, description, file_name } = response;
                    setImageLabel(label);
                    setImageDescription(description)
                    const url = process.env.REACT_APP_MEDIA_URL + "/" + file_name;
                    setUrl(url);
                })
        }
    }, [])
    const getPresignUrl = async (path, callback) => {
        let fileName = getFileName(file.name);
        let URL = process.env.REACT_APP_API_URL + "/get-presign-url";
        let payload = {
            'file_name': fileName,
            'label': imageLabel,
            'description': imageDescription
        }
        setUpload(true);
        await axios.post(URL, { 'file_name': fileName }, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response;
        }).then(async result => {
            const s3PresignedUrl = result.data;
            await axios({
                method: 'put',
                url: s3PresignedUrl,
                data: file,
            }).then(async (res) => {
                let insertImageURL = process.env.REACT_APP_API_URL + "/insert-image";
                await axios.post(insertImageURL, payload, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then(async (imageRes) => {
                    let imageId = imageRes.data._id;
                    setUpload(false)
                    // props.setLinkImagesList([...props.linkImagesList, imageRes.data])
                    if (fileRef) {
                        fileRef.current.value = null
                        setFile(null);
                    }
                    const url = process.env.REACT_APP_MEDIA_URL + "/" + fileName;
                    setUrl(url);
                    callback(path, imageId)
                })
            })
        });
    }
    const handleUpdateImage = async (imageId) => {
        let URL = process.env.REACT_APP_API_URL + '/update-image';
        await axios.post(URL, { 'label': imageLabel, 'description': imageDescription, '_id': imageId }, { headers: { Authorization: 'Bearer ' + user.jwtToken } }).then((response) => {
            setDisabled(true);
            Transforms.setNodes(editor, { imageId: imageId }, { at: path })
            setTimeout(() => {
                setDisabled(false)
            }, 500);
        })
    }

    switch (element.type) {
        case 'ref-wrapper':
            return (
                <div className='ref-wrapper rounded p-2 position-relative'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-3 bg-gray1 w-100 h-100'></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center text-white'>Reference</div>
                        <div className='d-flex align-items-center'>
                            {/* <span className='pointer material-icons me-2' onClick={() => {
                                Transforms.removeNodes(editor, { at: path })
                                Transforms.insertNodes(editor, {
                                    type: "ref-wrapper", children: [create("ref"), create("ref-dummy")]
                                }, { at: path })
                            }}>close</span> */}
                            <span className='pointer material-icons' onClick={() => { Transforms.removeNodes(editor, { at: path }) }}>delete</span>
                        </div>
                    </div>
                    {children}
                </div>
            )
        case 'ref':
            return (
                <div className='ref rounded border border-primary border-2 p-2 mb-2 position-relative'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-2 bg-white w-100 h-100'></div>
                    {(Editor.string(editor, path) === "") ? <><div className="position-absolute z-index-minus-1" >Enter Reference Content</div><div>{children}</div></> : children}
                </div>
            )
        case 'ref-dummy':
            return (
                <div className='ref-dummy user-select-none' contentEditable={false} style={{ height: "0px", overflow: 'hidden' }}>
                    {children}
                </div>
            )
        case 'accordion':
            return (
                <div className='rounded p-2 position-relative'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-4 bg-green3 w-100 h-100'></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center text-dark'>Accordion</div>
                        <div className='d-flex align-items-center'>
                            <span className='pointer material-icons' onClick={() => { Transforms.removeNodes(editor, { at: path }) }}>delete</span>
                        </div>
                    </div>
                    {children}
                </div>
            )
        case 'accordion-item':
            return (
                <div className='rounded p-2 mb-2 position-relative text-white'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-3  w-100 h-100' style={{ background : "black"}}></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center '>Accordion Item</div>
                        <div className='d-flex align-items-center'>
                            <span className='pointer material-icons me-2' onClick={() => {
                                Transforms.insertNodes(editor, {
                                    type: 'accordion-item',
                                    children: [create("accordion-title"), create("accordion-content")]
                                }, { at: Path.next(path) })
                            }}>add</span>
                            <span className='pointer material-icons' onClick={() => {

                                const match = Array.from(
                                    Editor.nodes(editor, {
                                        at: Path.parent(path),
                                        match: n =>
                                            !Editor.isEditor(n) &&
                                            SlateElement.isElement(n) &&
                                            n.type === "accordion-item",
                                        mode: "lowest"
                                    })
                                )
                                if (match.length === 1) {
                                    return
                                }
                                Transforms.removeNodes(editor, { at: path })
                            }}>delete</span>
                            {/* <span className='pointer material-icons' onClick={() => {
                                Transforms.removeNodes(editor, { at: path })
                                Transforms.insertNodes(editor, {
                                    type: 'accordion-item',
                                    children: [create("accordion-title"), create("accordion-content")]
                                }, { at: path })
                            }}>close</span> */}
                        </div>
                    </div>
                    {children}
                </div>
            )
        case 'accordion-title':

            return (
                <div className='rounded p-2 mb-2 position-relative text-body'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-2 bg-white w-100 h-100'></div>
                    {(Editor.string(editor, path) === "") ? <><div contentEditable={false} className="position-absolute z-index-minus-1">Enter Accordion Title</div>{children}</> : children}
                    {/* <div contentEditable={false} className="user-select-none">
                        <button className='btn btn-success btn-sm' onClick={() => {
                            Transforms.removeNodes(editor, { at: path })
                        }}>Clear</button>
                    </div> */}
                </div>
            )

        case 'accordion-content':
            return (
                <div className='rounded p-2 position-relative text-body'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-2 bg-white w-100 h-100'></div>
                    {(Editor.string(editor, path) === "") ? <><div contentEditable={false} className="position-absolute z-index-minus-1">Enter Accordion Content</div>{children}</> : children}
                    {/* <div contentEditable={false} className="user-select-none">
                        <button className='btn btn-success btn-sm' onClick={() => {
                            Transforms.removeNodes(editor, { at: path })
                        }}>Clear</button>
                    </div> */}
                </div>
            )
        case 'list':
            return (
                <div className='list rounded p-2 position-relative'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-3 bg-blue1 w-100 h-100' ></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center'>
                            <span className='me-2'>List</span>
                            <div className='btn-group btn-group-sm'>
                                <button className={'btn btn-outline-dark ' + (element.wrapperType === "number" ? "active" : "")} onClick={() => { Transforms.setNodes(editor, { wrapperType: "number" }, { at: path }) }}>1.</button>
                                <button className={'btn btn-outline-dark ' + (element.wrapperType === "bullet" ? "active" : "")} onClick={() => { Transforms.setNodes(editor, { wrapperType: "bullet" }, { at: path }) }}>*.</button>
                                <button className={'btn btn-outline-dark ' + (element.wrapperType === "alpha" ? "active" : "")} onClick={() => { Transforms.setNodes(editor, { wrapperType: "alpha" }, { at: path }) }}>a.</button>
                                <button className={'btn btn-outline-dark ' + (element.wrapperType === "roman" ? "active" : "")} onClick={() => { Transforms.setNodes(editor, { wrapperType: "roman" }, { at: path }) }}>i.</button>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <span className='pointer material-icons' onClick={() => { Transforms.removeNodes(editor, { at: path }) }}>delete</span>
                        </div>
                    </div>
                    {children}
                </div>
            )

        case 'list-item':
            return (
                <div className='list-item p-2 position-relative text-white mb-2' >
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-2  w-100 h-100' style={{ background: "black" }}></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center'>List Item</div>
                        <div className='d-flex align-items-center'> <span className='pointer material-icons' onClick={() => {
                            Transforms.insertNodes(editor, {
                                type: 'list-item',
                                children: [create("list-item-content"), create("list-item-dummy")]
                            }, { at: Path.next(path) })
                        }}>add</span>
                            <span className='pointer material-icons' onClick={() => {
                                const match = Array.from(
                                    Editor.nodes(editor, {
                                        at: Path.parent(path),
                                        match: n =>
                                            !Editor.isEditor(n) &&
                                            SlateElement.isElement(n) &&
                                            n.type === "list-item",
                                        mode: "lowest"
                                    })
                                )
                                if (match.length === 1) {
                                    return
                                }
                                Transforms.removeNodes(editor, { at: path })
                            }}>delete</span>
                            {/* <span className='pointer material-icons' onClick={() => {
                                Transforms.removeNodes(editor, { at: path })
                                Transforms.insertNodes(editor, {
                                    type: 'list-item',
                                    children: [create("list-item-content"), create("list-item-dummy")]
                                }, { at: path })
                            }}>close</span> */}
                        </div>
                    </div>
                    {children}
                </div>
            )
        case 'list-item-content':
            return (
                <div className='list-item-content position-relative p-2 text-body'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-2 bg-white w-100 h-100'></div>
                    {(Editor.string(editor, path) === "") ? <><div contentEditable={false} className="position-absolute z-index-minus-1">Enter List Item Content</div>{children}</> : children}
                </div>
            )
        case 'list-item-dummy':
            return (
                <div className='list-item-dummy user-select-none' contentEditable={false} style={{ height: "0px", overflow: 'hidden' }}>
                    {children}
                </div>
            )
        case 'link':
            return <LinkComponent {...props} />
        case 'content-link':
            return <ContentLinkComponent {...props} />
        case 'model-link':
            return <ModelLinkComponent {...props} />
        case 'image-link':
            return <ImageLinkComponent {...props} />
        case 'image-list':
            return (
                <div className='image-list rounded p-2 position-relative'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-3 bg-green2 w-100 h-100'></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center'>Image List</div>
                        <div className='d-flex align-items-center'>
                            <span className='pointer material-icons' onClick={() => {
                                // props.setLinkImagesList([])
                                Transforms.removeNodes(editor, { at: path })
                            }}>delete</span>
                        </div>
                    </div>
                    {children}
                </div>
            )
        case 'image':
            return (
                <div contentEditable={false} className='image user-select-none mb-2'>
                    <div className='position-relative p-2 text-white' style={{ background: "rgba(33,37,41)" }}>
                        <div className='position-absolute rounded top-0 start-0 z-index-minus-2 bg-dark w-100 h-100'></div>
                        <div className="d-flex justify-content-between mb-2">
                            <div className='d-flex align-items-center'>Image Item</div>
                            <div className='d-flex align-items-center'>
                                <span className='pointer material-icons' onClick={() => {
                                    Transforms.insertNodes(editor, { type: 'image', url: "", children: [{ text: '' }] }, { at: Path.next(path) })
                                }}>add</span>
                                <span className='pointer material-icons' onClick={() => {
                                    const match = Array.from(
                                        Editor.nodes(editor, {
                                            at: Path.parent(path),
                                            match: n =>
                                                !Editor.isEditor(n) &&
                                                SlateElement.isElement(n) &&
                                                n.type === "image",
                                            mode: "lowest"
                                        })
                                    )
                                    if (match.length === 1) {
                                        return
                                    }
                                    // let images = props.linkImagesList.filter((img) => img._id !== element.imageId)
                                    // props.setLinkImagesList([...props.linkImagesList, ...images])
                                    Transforms.removeNodes(editor, { at: path })
                                }}>delete</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <input ref={fileRef} type="file" name="image" onChange={(e) => {
                                    setFile(e.target.files[0])
                                }} />
                                {file && <button className="btn btn-green2" type="submit" onClick={() => {
                                    getPresignUrl(path, (path, imageId) => {
                                        Transforms.setNodes(editor, { imageId: imageId }, { at: path })
                                    })
                                }}>{upload ? "Uploading" : "Upload"} </button>}
                                {file && <button className="btn btn-green2 ms-2" type="submit" onClick={() => {
                                    if (fileRef) {
                                        fileRef.current.value = null
                                        setFile(null);
                                    }
                                }}>Cancel </button>}
                                {/* <input type="text" className='form-control' value={element.url} onChange={(e) => {
                                }} placeholder="Enter Image Url" /> */}
                            </div>
                            <div className='col-sm-4 mb-5'>
                                {url && isUrl(url) ? !upload ? (<img
                                    src={url}
                                    style={{ display: "block", maxWidth: "100%", maxHeight: "20em", boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}` }} />) : (<div>Uploading</div>) : (<></>)}
                            </div>

                            <div className='col-sm-8 mt-3'>
                                <label className="form-label">
                                    Label :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="imageLabel"
                                    defaultValue={imageLabel}
                                    onChange={(e) => {
                                        setErrorMessage(false)
                                        setImageLabel(e.target.value);
                                    }}
                                />
                            </div>
                            <div className='col-sm-8 mt-3'>
                                <label className="form-label">
                                    Description:
                                </label>
                                <textarea
                                    rows="4" cols="50"
                                    className="form-control"
                                    defaultValue={imageDescription}
                                    name="imageDescription"
                                    onChange={(e) => {
                                        setErrorMessage(false)
                                        setImageDescription(e.target.value);
                                    }}
                                />
                            </div>
                            {errorMessage && <div className="text-danger mt-2">Plese enter label and description</div>}</div>
                        <div className='mt-3'><button className='col-sm-2 btn btn-green2'
                            type="submit"
                            disabled={disabled}
                            onClick={() => {
                                handleUpdateImage(element.imageId);
                            }}>{!disabled ? 'Save' : 'Saving ..'}</button></div>
                        <div className="mt-1">
                        </div>
                    </div>
                    {children}
                </div >
            )
        case 'video-list':
            return (
                <div className='video-list rounded p-2 position-relative'>
                    <div className='position-absolute rounded top-0 start-0 z-index-minus-3 bg-danger w-100 h-100'></div>
                    <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                        <div className='d-flex align-items-center'>Video List</div>
                        <div className='d-flex align-items-center'>
                            <span className='pointer material-icons' onClick={() => { Transforms.removeNodes(editor, { at: path }) }}>delete</span>
                        </div>
                    </div>
                    {children}
                </div>
            )
        case 'video':
            return (
                <div contentEditable={false} className='video user-select-none mb-2'>
                    <div className='position-relative p-2 text-white'>
                        <div className='position-absolute rounded top-0 start-0 z-index-minus-2 bg-dark w-100 h-100'></div>
                        <div className="d-flex justify-content-between mb-2">
                            <div className='d-flex align-items-center'>Video Item</div>
                            <div className='d-flex align-items-center'>
                                <span className='pointer material-icons' onClick={() => {
                                    Transforms.insertNodes(editor, { type: 'video', url: "", children: [{ text: '' }] }, { at: Path.next(path) })
                                }}>add</span>
                                <span className='pointer material-icons' onClick={() => {
                                    const match = Array.from(
                                        Editor.nodes(editor, {
                                            at: Path.parent(path),
                                            match: n =>
                                                !Editor.isEditor(n) &&
                                                SlateElement.isElement(n) &&
                                                n.type === "video",
                                            mode: "lowest"
                                        })
                                    )
                                    if (match.length === 1) {
                                        return
                                    }
                                    Transforms.removeNodes(editor, { at: path })
                                }}>delete</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <input type="text" className='form-control' value={element.url} onChange={(e) => {
                                    Transforms.setNodes(editor, { url: e.target.value }, { at: path })
                                }} placeholder="Enter Video Url" />
                            </div>
                            <div className='col-sm-4'>
                                {element.url && isUrl(element.url) ? (<iframe width="100%" height="auto" src={element.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>) : (<></>)}
                            </div>
                        </div>
                    </div>
                    {children}
                </div>

            )
        case 'table-wrapper' : 
            return  <div className='ref-wrapper rounded p-2 position-relative'>
            <div className='position-absolute rounded top-0 start-0 z-index-minus-3 w-100 h-100' style={{ background : "aliceblue" , border : "1px solid black"}}></div>
            <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                <div className='d-flex align-items-center text-black'>Table</div>
                <div className='d-flex align-items-center'>
                    <span className='pointer material-icons' onClick={() => { Transforms.removeNodes(editor, { at: path }) }}>delete</span>
                </div>
            </div>
            {children}
        </div>
        case 'table':
            return (
                <table>
                    <tbody {...attributes}>{children}</tbody>
                </table>
            )
        case 'table-row':
            return <tr {...attributes}>{children}</tr>
        case 'table-cell':
            return <td {...attributes}>{children}</td>
        case 'paragraph' :
             if(element.children && element.children[0] && element.children[0].text === ''){
               return <div className='rounded p-2 position-relative'>
               <div className='position-absolute rounded top-0 start-0 z-index-minus-3 w-100 h-100' style={{ border : "1px solid red"}}></div>
                   <div contentEditable={false} className="user-select-none d-flex justify-content-between mb-2">
                   <div className='d-flex align-items-center text-danger'></div>
                   <div className='d-flex align-items-center'>
                       <span className='text-danger'>Paragraph</span>
                       <span className='pointer material-icons' onClick={() => { Transforms.removeNodes(editor, { at: path }) }}>delete</span>
                   </div>
                   </div>
                   <p {...attributes}>
                    {children}
                </p>
                </div>
             }
        default:
            return (
                <p {...attributes}>
                    {children}
                </p>
            )
    }
}
export default RichEditorElement;