import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";


function Profile() {
  let navigate = useNavigate();
  const { avatar, signout, firstName: authFirstName, lastName: authLastName, user } = useAuth();
  function logout() {
    signout(() => {
      navigate("/");
    })
  }
  return (
    <div className="dropdown">
      <div
        role="button"
        className="avatar fs-6 fw-bold"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          alt=""
          src={avatar ? `${process.env.REACT_APP_MEDIA_URL}/avatar/${avatar}` : "/images/avatar.png"}
          className="rounded-circle border border-2 border-white bg-white img-fluid"
          style={{ width: "3rem", height: "3rem" }}
        />
      </div>
      <div
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby="dropdownMenuButton1"
      >
        <ul className="p-1 m-0">
          <li className="d-flex justify-content-center">
            <img
              alt=""
              src={avatar ? `${process.env.REACT_APP_MEDIA_URL}/avatar/${avatar}` : "/images/avatar.png"}
              className="rounded-circle border border-2 border-white bg-white img-fluid me-2"
              style={{ width: "2rem", height: "2rem" }}
            />
            <span className="fs-5 fw-bolder">{authFirstName} {authLastName}</span>
          </li>
        </ul>
        <div>
          <Link
            to="/my-account"
            className="dropdown-item fw-bolder fs-6"
          >
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                My Account
              </div>
              <div className="col-3"></div>
            </div>
          </Link>

          <Link
            to="/dashboard"
            className="dropdown-item fw-bolder fs-6"
          >
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                Dashboard
              </div>
              <div className="col-3"></div>
            </div>
          </Link>
          {user && user.role === "Admin" && <Link
            to="/users"
            className="dropdown-item fw-bolder fs-6"
          >
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                Users
              </div>
              <div className="col-3"></div>
            </div>
          </Link>}

          <span
            role="button"
            className="dropdown-item fw-bolder fs-6"
            onClick={logout}
          >
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                Logout
              </div>
              <div className="col-3"></div>
            </div>

          </span>
        </div>
      </div>
    </div>
  );
}
export default Profile;
