import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Select from 'react-select';
import data from "../data/search.json";

function ModelContentLink(props) {
  const [selectedValue, setSelectedValue] = useState(null);
  
  // handle selection
  const handleChange = (value) => {
    setSelectedValue(value);
    props.setContentLink(value);
  };

  useEffect(() => {
    if (props.contentLink != null) {
      let filterdata = data.filter(a => a.value == props.contentLink)
      const defaultSelectedOption = { value: props.contentLink, label: filterdata[0].label };
      setSelectedValue(defaultSelectedOption);
    }
  }, []);

  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        className={"modal fade " + (props.modal ? "show " : "")}
        id="exampleModal"
        tabIndex="-1"
        style={props.modal ? { display: "block" } : { display: "none" }}
      >
        <div
          className={
            "modal-dialog modal-dialog-centered modal-lg"
          }
        >
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="exampleModalLabel">
                Add Content Link
              </h2>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  props.setModal(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{height : 300}}>
              <form className="row g-3 needs-validation" onSubmit={() => { }}>
                <div className="col-md-12">
                  <label htmlFor="validationCustom02" className="form-label">
                    Add content link
                  </label>
                  <Select
                    // closeMenuOnSelect={false}
                    options={data}
                    onChange={handleChange}
                    maxMenuHeight = {200}
                    value={selectedValue}
                   />
                  <div className="valid-feedback">Looks good!</div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  props.handleSaveContentLink()
                  props.setModal(false)
                }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  props.setModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.modal ? <div className="modal-backdrop fade show" /> : ""}
    </React.Fragment>,
    document.getElementById("modal-root")
  );
}
export default ModelContentLink;
