

function SignUpLayout({ partners }) {
  return (
    <section className="left-gradient-content h-100">
      <div className="p-5 h-100 d-flex flex-column justify-content-between">
        <div className="logo">
          <img src="/images/Anatomy-Unlocked-Brandmark-White.png" alt="" style={{ width: "12rem" }} />
        </div>
        <div className="">
          <h1 className="lead mb-3 fs-1 text-white  fw-bold">
            Welcome to
            <br />
            Anatomy Unlocked.
          </h1>
          <p className="text-white fs-4">Accelerate your learning to become a better clinician.</p>
        </div>
        <div className="h-25">
          <p className="text-white text-left fs-4">Our industry partners and contributors</p>
          <div className="d-flex justify-content-between align-items-center w-75">
            <img src="/images/logo/UWA-logo.png" alt="" className="" style={{ height: "2.5rem" , paddingRight : 20 }} />
            <img src="/images/logo/Perth-Radiological-clinic-logo.png" alt="" className="" style={{ height: "5rem", paddingRight : 20  }} />
            <img src="/images/logo/Vection-logo.png" alt="" className="" style={{ height: "3rem"  }} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUpLayout;
